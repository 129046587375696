<template>
    <BiensItem
        class="mt-2"
        :sciUuid="sciUuid"
    />
</template>

<script>

import BiensItem from './../../components/bien/list/BiensItem';

export default {
    components: {
        BiensItem
    },
    data() {
        return {
            sciUuid: null
        }
    },
    created() {
        this.sciUuid = this.$route.params.sciUuid
    }
};
</script>