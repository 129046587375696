<template>
    <div class="flex flex-col justify-between">
        <div class="flex justify-items-center items-center" v-for="(habitant, index) of habitants" :key="index">
            <SwitchForm v-model='habitant.selected' :id="`${index}-habitant`" class="my-3 mr-2" @edit="edit"/>
            <HabitantRel :habitant="habitant.data" :locataireUuid="habitant?.data?.locataire?.uuid" :disabled="!habitant.selected" class="mt-1"/>
        </div>
    </div>
</template>
    
<script>
    import SwitchForm from './../SwitchForm.vue'
    import HabitantRel from '../../composant/habitant/HabitantRel.vue'

    export default {
        components: {
            SwitchForm,
            HabitantRel
        },
        data() {
            return {
                habitants: {
                    type: Array,
                    default: () => {
                        return []
                    }
                }
            }
        },
        props: {
            modelValue: Array,
        },
        created() {
            this.habitants = this.modelValue
        },
        methods: {
            edit() {
                this.$emit('update', this.habitants)
            }
        },
    };
</script>

