<template>
    <PrimaryModal
        title="Éditer un habitant"
        ref="editHabitantModal"
        btnSuccessText="Éditer"
        :disabledBtn="checkFieldValid"
        @ok="editHabitant()"
        :width="750"
    >
        <template v-slot:content>
            <div class="md:col-span-3">
                <div class="grid grid-cols-1 md:grid-cols-3">
                    <DatePicker 
                        v-model='habitant.date_entree' 
                        class="mt-3 mr-2" 
                        title="Date d'entrée" 
                        id='dateEntree' 
                    />
                    <DatePicker 
                        v-model='habitant.date_sortie' 
                        class="mt-3 mr-2" 
                        title="Date de sortie" 
                        id='dateSortie' 
                    />
                    <AppInput 
                        v-model='habitant.security_deposit'
                        :validations="['number']"
                        class="mt-3"
                        title='Dépot de garantie'
                        id='security_deposit'
                        icon="euro-sign"
                    />
                </div>
            </div>
        </template>
    </PrimaryModal>
</template>

<script>

import AppInput from './../../form/AppInput';
import DatePicker from './../../form/DatePicker';
import PrimaryModal from './../../composant/PrimaryModal';

export default {
    components: {
        PrimaryModal,
        AppInput,
        DatePicker
    },

    props: {
        habitantData: {
            type: Object,
            default() {
                return {
                    date_entree: null,
                    date_sortie: null,
                    security_deposit: null,
                }
            }
        },
    },

    data() {
        return {
            habitant: {
                date_entree: null,
                date_sortie: null,
                security_deposit: null,
            },
            formInvalid: []
        }
    },

    computed: {
        checkFieldValid() {
            return this.formInvalid.length !== 0;
        }
    },

    methods: {
        openModal() {
            this.habitant = this.habitantData
            this.$refs.editHabitantModal.openModal();
        },

        async editHabitant() {
            const bienUuid = this.$route.params.bienUuid

            if (this.checkFieldValid || !bienUuid || (this.habitant && !this.habitant.uuid)) {
                return;
            }

            this.$refs.editHabitantModal.activateLoader();

            await this.$api.habitant.editHabitant(bienUuid, this.habitant.uuid, {
                date_entree: this.habitant.date_entree ? this.habitant.date_entree.toISOString() : undefined,
                date_sortie: this.habitant.date_sortie ? this.habitant.date_sortie.toISOString() : undefined,
                security_deposit: this.habitant.security_deposit ? parseFloat(this.habitant.security_deposit) : undefined
            })
            .then((result) => {
                this.$toast({title: 'Succès', description: "L'habitant à été édité avec succès."}, this.$toastSuccess);

                return Promise.resolve(result)
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.editHabitantModal.deactivateLoader();
            this.$refs.editHabitantModal.dismissModal();
            this.$emit('handleUpdateList');
        },
        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },
    },

    watch: {
        habitantData(newVal) {
            this.habitant = newVal
        }
    },
};
</script>