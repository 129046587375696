const validator = {
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        return re.test(String(email).toLowerCase());
    },

    hasNumber(string) {
        const re = /[0-9]/

        return re.test(String(string))
    },

    hasLetter(string) {
        const re = /[a-zA-Z]/

        return re.test(String(string))
    },
    
    hasCaseDiff(string) {
        const re = /(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})/u
        
        return re.test(String(string))
    },

    hasSpecialChar(string) {
        const re = /[^\p{Ll}\p{Lu}\p{L}\p{N}]/u

        return re.test(String(string))
    },

    isOnlyNumber(string) {
        const re = /^[0-9]*$/

        return re.test(String(string))
    },

    isNumber(string) {
        const re = /^\d+(\.\d{1,2})?$/

        return re.test(String(string))
    },

    isYear(string) {
        const re = /^[12][0-9]{3}$/

        return re.test(String(string))
    },

    validateEmailMessage() {
        return `Cet email n'est pas valide.`
    },

    requiredMessage() {
        return `Ce champ est obligatoire.`
    },

    onlyIntegerMessage() {
        return `Ce champ ne peux contenir que des nombres entier positif.`
    },

    onlyNumberMessage() {
        return `Ce champ ne peut contenir que des nombres décimaux. (Contenant 2 décimals max)`
    },

    onlyYearMessage() {
        return `Ce champ ne peut contenir que des années. (4 chiffres)`
    }
};
  
  export default validator;