export default {
    monthOptions: [
        {
            code: "01",
            label: "Janvier"
        },
        {
            code: "02",
            label: "Février"
        },
        {
            code: "03",
            label: "Mars"
        },
        {
            code: "04",
            label: "Avril"
        },
        {
            code: "05",
            label: "Mai"
        },
        {
            code: "06",
            label: "Juin"
        },
        {
            code: "07",
            label: "Juillet"
        },
        {
            code: "08",
            label: "Aout"
        },
        {
            code: "09",
            label: "Septembre"
        },
        {
            code: "10",
            label: "Octobre"
        },
        {
            code: "11",
            label: "Novembre"
        },
        {
            code: "12",
            label: "Décembre"
        }
    ],
    dayOptions: [
        '',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
    ]
}