<template>
    <div class="mt-5">
        <hr>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-5">
            <router-link 
                class="cursor-pointer bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 md:mx-1 my-3" 
                v-for="(bien, index) in biens" :key="index"
                :to="`/bien/${bien.uuid}`"
            >
                <div class="rounded-t-lg" v-if="bien.images">
                    <img class="rounded-t-lg" src="/docs/images/blog/image-1.jpg" alt="" />
                </div>
                <div class="rounded-t-lg h-64 md:h-48 bg-gray-200 flex justify-center items-center" v-else>
                    <font-awesome-icon class="text-3xl text-gray-600" :icon="['fa', defaultIcon(bien)]" />
                </div>

                <div class="p-5">
                    <h3 class="text-indigo-500 cursor-pointer inline-block text-lg">{{bien.libelle}}</h3>

                    <div class="my-3 text-sm font-normal text-gray-500 dark:text-gray-400">
                        <p>{{ bien.ville }}
                            <template v-if="bien.ville && bien.cp"> - </template>{{ bien.cp }}</p>
                        <p>{{ bien.adresse }}</p>
                    </div>
                    <div class="flex justify-end">

                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        biens: {
            type: Array,
            default() {
                return []
            }
        },
    },

    methods: {
        defaultIcon(bien) {
            if (bien.type === 'appartement') {
                return 'building'
            }

            if (bien.type === 'immeuble') {
                return 'hotel'
            }

            return 'home';
        }
    }
};
</script>