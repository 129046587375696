<template>
    <div class="flex flex-col justify-between">
        <label v-if="!labelInline && !hideLabel" class="form-label m-0" :for="id">
            {{title}}{{ validations.includes('required') ? '*' : ''}}
        </label>
        <div 
            class="flex items-center space-x-3 mt-1"
            v-if="!disabled"
        >
            <input type="checkbox" :checked="isCheck" :value="isCheck" :name="id" hidden />
            <label v-if="labelInline && !hideLabel" class="form-label mb-0" :for="id">
                {{title}}{{ validations.includes('required') ? '*' : ''}}
            </label>
            <label v-if="labelLeft" class="form-label m-0" :for="id">
                {{labelLeft}}
            </label>
            <span role="checkbox"
                :aria-checked="isCheck"
                @click="toggle"
                :class="isCheck ? 'bg-indigo-600' : 'bg-gray-200'"
                tabindex="0" aria-labelledby="toggleLabel" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
            >
                <span aria-hidden="true"
                    :class="isCheck ? 'translate-x-5' : 'translate-x-0'"
                    class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200">
                </span>
            </span>
            <label v-if="labelRight" class="form-label m-0" :for="id">
                {{labelRight}}
            </label>
        </div>
        
        <p v-if="hasError" class="text-red-500 text-xs italic">{{ displayError }}</p>
    </div>
</template>
    
<script>
    export default {
        data() {
            return {
                validationError: '',
                isCheck: 0
            }
        },
        props: {
            modelValue: Number,
            title: String,
            id: {
                type: String,
                required: true,
            },
            required: {
                type: Boolean,
                default: false,
            },
            labelInline: {
                type: Boolean,
                default: false,
            },
            hideLabel: {
                type: Boolean,
                default: false
            },
            labelLeft: {
                type: String,
                default: undefined
            },
            labelRight: {
                type: String,
                default: undefined
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            error: String,
            validations: {
                type: Array,
                default: () => []
            }
        },
        created() {
            this.isCheck = this.modelValue
        },
        computed: {
            hasError() {
                return !!(this.error || this.validationError);
            },
            displayError() {
                if (this.error) {
                    return this.error;
                }
    
                if (this.validationError) {
                    return this.validationError;
                }
    
                return '';
            }
        },
        methods: {
            toggle() {
                if (this.isCheck === 0) {
                    this.isCheck = 1
                } else {
                    this.isCheck = 0
                }

                this.$emit('update:modelValue', this.isCheck);
                this.$emit('edit')
            }
        },
        watch: {
            hasError(newValue) {
                this.$emit('hasError', {name: this.id, value: newValue});
            },

            modelValue(newValue) {
                this.isCheck = newValue
            }
        }
    };
    </script>

