<template>
    <div class="flex">
        <div class="flex justify-items-end">
            <div 
                :class="modelValue ? '-translate-x-0 ease-out' : 'translate-x-full ease-in'"
                class="fixed inset-y-0 right-0 max-w-11/12 z-50 custom-width overflow-y-auto transition duration-300 transform bg-white border-l-2 border-indigo-100"
            >
                <div class="h-screen flex flex-col">
                    <div class="flex justify-between items-center h-16 border-b border-indigo-500 p-4">
                        <h3 class="text-indigo-500 text-lg font-semibold">{{ title }}</h3>
                        <font-awesome-icon class="text-lg mr-2 cursor-pointer" :icon="['fa', 'times']" @click="dismissModal()" />
                    </div>
                    <div class="flex-grow overflow-y  overflow-auto p-2"> 
                        <slot name="content"></slot>
                    </div>
                    <div class="flex justify-between h-20 border-t border-indigo-500 p-5">
                        <button
                            v-if="btnSecondary"
                            class="flex justify-center items-center mr-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border text-sm border-gray-500 hover:border-transparent rounded-md"
                            @click="$emit('secondaryAction')"
                        >
                            {{ btnSecondaryText }}
                        </button>
                        <span v-else></span>
                        <LoaderButton :title="btnSuccessText" :defaultClass="classButton" @ok="validate()" :disabled="disabledBtn" ref="btnValidate"/>
                    </div>
                </div>
            </div>
        </div>
        <div
            @click.prevent="dismissModal"
            :class="modelValue ? 'block' : 'hidden'"
            class="fixed inset-0 z-20 transition-opacity bg-black opacity-50">
        </div>
  </div>
</template>

<script>

import LoaderButton from './../form/LoaderButton';

export default {
    components: {
        LoaderButton
    },
    emits: [
        'ok', 
        'secondaryAction',
        'update:modelValue'
    ],
    props: {
        modelValue: [Boolean],
        title: String,
        btnSuccessText: {
            type: String,
            default: "Valider"
        },
        disabledBtn: {
            type: Boolean,
            default: false,
        },
        classButton: {
            type: String,
            default: "btn-primary"
        },
        btnSecondary: {
            type: Boolean,
            default: false
        },
        btnSecondaryText: {
            type: String,
            default: "Annuler"
        }
    },
    data() {
        return {
            showModal: false
        }
    },
    methods: {
        dismissModal() {
            this.$emit('update:modelValue', false);
        },
        validate() {
            this.$emit('ok')
            this.$emit('update:modelValue', false);
        }
    },
};
</script>
<style lang="scss" scoped>
.custom-width {
    width: 450px;
    max-width: 90%;
}
</style>