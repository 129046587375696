import api from "./api";
import { catchValidationErrors } from "./catchErrors";

class LocataireService {
    async getLocataires(page, form = {}) {
        const { data } = await api.get(`/api/locataires?page=${page}`, { params: form }).catch(catchValidationErrors);

        return data;
    }

    async getLocataire(locataire_uuid) {
        const { data } = await api.get(`/api/locataires/${locataire_uuid}`).catch(catchValidationErrors);

        return data;
    }

    async updateLocataire(locataire_uuid, form) {
        const { data } = await api.put(`/api/locataires/${locataire_uuid}`, {...form}).catch(catchValidationErrors);

        return data;
    }
}

export default new LocataireService();
