import AuthService from '../services/auth.service';
import router from '@/router';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        async login({ commit }, user) {
            const response = await AuthService.login(user).catch(
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );

            await commit('loginSuccess', response);
            router.push({ name: 'dashboard'});
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
                    
            router.push({ name: 'Login'});
        },
        refreshToken({ commit }, token) {
            commit('refreshToken', token);
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        refreshToken(state, token) {
            state.status.loggedIn = true;
            state.user = { ...state.user, token: token };
        }
    }
};