<template>
    <div class="bg-white rounded-xl p-6">
        <TabsItem v-model="activeTab" :tabs="tabs" return-route="sci" @input="changeTab"/>
        <router-view></router-view>
    </div>
</template>

<script>

import TabsItem from './../components/composant/TabsItem';

export default {
    components: {
        TabsItem
    },
    data() {
        return {
            sci: {},
            activeTab: "sciDetailsDetails",
            tabs: [
                { label: "Détails", name: "sciDetailsDetails", icon: "book"},
                { label: "Biens", name: "sciDetailsBiens", icon: "home"},
                { label: "Paramètres", name: "sciDetailsSettings", icon: "cog"},
            ]
        }
    },
    created() {
        this.loadRoute(this.$route.name);
    },
    methods: {
        loadRoute(currentRoute) {
            if (currentRoute === "sciDetails") {
                this.$router.push({name: this.tabs[0].name})
            }

            this.activeTab = currentRoute
        },
        changeTab(routeName) {
            this.$router.push({ name: routeName });
        },
    },
    watch: {
        $route(current) {
            this.loadRoute(current.name)
        }
    }
};
</script>