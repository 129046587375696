<template>
    <div class="flex flex-wrap justify-between">
        <h1 class="text-2xl text-bold">Sécurité</h1>
        <button class="btn-primary" @click='openChangePasswordModal()'>
            Changer de mot de passe
        </button>
        <PrimaryModal
            title="Changer votre mot de passe"
            ref="changePasswordModal"
            :disabledBtn="!checkFormValid()"
            btnSuccessText="Modifier"
            @ok="editPassword"
        >
            <template v-slot:content>
                <div class="-space-y-px">
                    <div>
                        <AppInput 
                            v-model='oldPassword' 
                            title='Mot de passe actuel' 
                            id='new-password' 
                            :required="true" 
                            :error="oldPasswordError"
                            type="password"
                        />
                    </div>
                    <div class="pt-5">
                        <label for="password" class="form-label">Nouveau mot de passe*</label>
                        <input 
                            v-model="password"
                            name="password"
                            type="password"
                            autocomplete="off"
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            @input="checkStrength()"
                        />
                    </div>
                    <div class="flex justify-start -mx-1 pt-3 pl-4">
                        <ul class="text-left text-sm">
                            <li :class="formSuccess.minimal ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.minimal" :icon="['fas', formSuccess.minimal ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                8 charactères minimum
                            </li>
                            <li :class="formSuccess.number ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.number" :icon="['fas', formSuccess.number ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                Un chiffre minimum
                            </li>
                            <li :class="formSuccess.letter ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.letter" :icon="['fas', formSuccess.letter ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                Une lettre minimum
                            </li>
                            <li :class="formSuccess.case ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.case" :icon="['fas', formSuccess.case ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                Au moins une majuscule et une minuscule
                            </li>
                            <li :class="formSuccess.special ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.special" :icon="['fas', formSuccess.special ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                Un charactère spécial
                            </li>
                        </ul>
                    </div>
                    <div class="pt-8">
                        <label for="retypePassword" class="form-label">Répéter le mot de passe*</label>
                        <input 
                            v-model="retypePassword"
                            name="retypePassword"
                            type="password"
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            @input="checkStrength()"
                            @keyup.enter="handleNewPassword()"
                        />
                    </div>
                    <div class="flex justify-start -mx-1 pt-3 pl-4">
                        <ul class="text-left text-sm">
                            <li :class="formSuccess.same ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.same" :icon="['fas', formSuccess.same ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                Les mots de passe ne sont pas identique
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
            <template v-slot:footer>   
                <p class="text-yellow-500">
                    <font-awesome-icon :icon="['fa', 'exclamation-circle']" />
                    Attention, modifier votre mot de passe vous déconnectera de l'application.
                </p>
            </template>
        </PrimaryModal>
    </div>
</template>

<script>

import PrimaryModal from './../composant/PrimaryModal';
import AppInput from './../form/AppInput';

export default {
    components: {
        PrimaryModal,
        AppInput
    },
    data() {
        return {
            oldPassword: '',
            oldPasswordError: '',
            password: '',
            retypePassword: '',
            formSuccess: {
                minimal: false,
                number: false,
                letter: false,
                case: false,
                special: false,
                same: false,
            },
        }
    },
    props: {
        userUuid: String
    },
    methods: {
        openChangePasswordModal() {
            this.$refs.changePasswordModal.openModal();
        },
        checkStrength() {
            this.formSuccess.minimal = this.password.length >= 8;
            this.formSuccess.number = this.$validator.hasNumber(this.password);
            this.formSuccess.letter = this.$validator.hasLetter(this.password);
            this.formSuccess.case = this.$validator.hasCaseDiff(this.password);
            this.formSuccess.special = this.$validator.hasSpecialChar(this.password);
            this.formSuccess.same = this.password && this.retypePassword && this.password === this.retypePassword;

            return this.formSuccess.minimal && 
                this.formSuccess.number && 
                this.formSuccess.letter && 
                this.formSuccess.case &&
                this.formSuccess.special && 
                this.formSuccess.same
        },
        checkFormValid() {
            return this.checkStrength() && this.oldPassword.length > 0;
        },
        async editPassword() {
            if (!this.checkFormValid()) {
                return;
            }
            this.oldPasswordError = '';
            this.$refs.changePasswordModal.activateLoader();

            const { password, oldPassword } = this 
            await this.$api.user.changeUserPassword(this.userUuid, {
                password,
                oldPassword,
            })
            .then(() => {
                this.clearChangeModalPassword()
                this.$toast({title: 'Succès', description: "Le mot de passe à été modifié avec succès."}, this.$toastSuccess);
                this.$store.dispatch('auth/logout');
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.type && err.response.data.type === "invalid_password") {
                    this.oldPasswordError = 'Le mot de passe est invalide.';
                }
                this.$refs.changePasswordModal.deactivateLoader();
            }) 
        },
        clearChangeModalPassword() {
            this.oldPassword = '';
            this.password = '';
            this.retypePassword = '';
            this.$refs.changePasswordModal.deactivateLoader();
            this.$refs.changePasswordModal.dismissModal();
        }
    },
};
</script>