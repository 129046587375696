<template>
    <div class="mt-3">
        <div class="flex justify-between items-center bg-indigo-600 rounded-lg py-3 cursor-pointer px-3" @click="openLoyer = !openLoyer">
            <h2 class="text-white text-lg">Loyer</h2>
            <span class="text-sm text-white">
                <font-awesome-icon v-if="openLoyer" :icon="['fa', 'chevron-down']" />
                <font-awesome-icon v-else :icon="['fa', 'chevron-right']" />
            </span>
        </div>

        <div class="overflow-x-auto overflow-y-visible" v-if="openLoyer">
            <PaginationBlock v-if="paginator && paginator.total > 0" :paginator="paginator" @changePage="changePage"/>
            <table class="w-full border-t-2 border-gray-600">
                <thead>
                    <tr class="">
                        <th scope="col" class="text-left py-2 px-2 text-sm font-normal rtl:text-right text-gray-500 dark:text-gray-400">
                            Date
                        </th>
                        <th scope="col" class="text-left py-2 px-2 text-sm font-normal rtl:text-right text-gray-500 dark:text-gray-400">
                            Loyer
                        </th>
                        <th scope="col" class="text-left py-2 px-2 text-sm font-normal rtl:text-right text-gray-500 dark:text-gray-400">
                            Habitants
                        </th>
                        <th scope="col" class="text-left py-3.5 px-2 flex justify-end items-center">
                            <button class="btn btn-primary" @click="$refs.addLoyerModal.openModal()">
                                <font-awesome-icon class="text-md" :icon="['fa', 'plus']" />
                            </button>
                            <EditLoyerModal
                                ref="addLoyerModal"
                                @handleUpdateList="loadLoyer"
                                :bien="bien"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template
                        v-if="paginator && paginator.results && paginator.results.length !== 0"
                    >
                        <template
                            v-for="loyer of paginator.results" :key="loyer.uuid"
                        >
                            <LoyerItem :loyer="loyer" @refresh="loadLoyer" />
                        </template>

                    </template>
                    <tr
                        class="px-2 py-3 border-t border-gray-200"
                        v-else-if="!loaded"
                    >
                        <td colspan="5">
                            <SpinnerLoader class="mt-5"></SpinnerLoader>
                        </td>
                    </tr>
                    <tr
                        class="px-2 py-3 border-t border-gray-200"
                        v-else
                    >
                        <td colspan="5" class="text-center">
                            <p class="text-sm text-indigo-400 my-4">Aucun loyer n'a été trouvé.</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import SpinnerLoader from './../../../loader/SpinnerLoader';
import PaginationBlock from './../../../composant/PaginationBlock';
import LoyerItem from './../../../bien/details/financials/LoyerItem';
import EditLoyerModal from './../../../modal/bien/financials/loyer/EditLoyerModal';
export default {
    components: {
        SpinnerLoader,
        PaginationBlock,
        LoyerItem,
        EditLoyerModal
    },

    data() {
        return {
            paginator: {
                results: []
            },
            loaded: false,
            addLoader: false,
            form: {
                year: new Date().getFullYear(),
                amount: null,
                paiementDate: null
            },
            formInvalid: [],
            openLoyer: false,
        }
    },

    props: {
        bien: {
            type: Object,
            required: true
        },
    },

    created() {
        this.loadLoyer()
    },

    computed: {
        checkFieldValid() {
            return this.formInvalid.length === 0 && this.form.year.length !== 0 && this.form.amount;
        }
    },

    methods: {
        async changePage(page) {
            await this.loadLoyer(page)
        },

        async loadLoyer(page = 1) {
            this.loaded = false
            this.paginator.results = []

            this.paginator = await this.$api.bien.getLoyers(page, this.$route.params.bienUuid);

            this.loaded = true
        },

        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },
    }
};
</script>
