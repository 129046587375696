<template>
    <div v-if="biens && biens.length !== 0">
        <TableLayout v-if="display === 'table'" :biens="biens" />
        <CardLayout v-else :biens="biens"/>
    </div>
    <div class="mt-5" v-else>
        <hr>
        <div class="flex flex-col items-center justify-center mt-5">
            <p class="text-sm text-indigo-400">Aucun bien n'a été trouvé.</p>
            <p @click="$emit('resetFilter')" class="text-sm text-indigo-600 cursor-pointer hover:text-indigo-300 mt-2">
                <font-awesome-icon class="text-lg" :icon="['fa', 'eraser']" />
                Réinitialiser les filtres.
            </p>
        </div>
    </div>
</template>

<script>

import CardLayout from './display/CardLayout';
import TableLayout from './display/TableLayout';

export default {
    components: {
        CardLayout,
        TableLayout
    },

    props: {
        biens: {
            type: Array,
            default() {
                return []
            }
        },
        display: {
            type: String,
        }
    },

    computed: {
        hasError() {
            return !!(this.error || this.validationError);
        },
        displayError() {
            if (this.error) {
                return this.error;
            }

            if (this.validationError) {
                return this.validationError;
            }

            return '';
        }
    },
};
</script>