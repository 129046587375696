<template>
    <div>
        <div class="bg-white rounded-xl p-6">
            <div class="flex justify-between items-center mb-3" v-if="title">
                <h1 class="text-2xl text-bold">{{ title }}</h1>
                <div class="flex">
                    <span 
                        :class="[display === 'table' ? activeClass : inactiveClass]"
                        class="flex justify-center border rounded-md cursor-pointer py-2 px-3"
                        @click="changeDisplay('table')"
                    >
                        <span><font-awesome-icon class="text-lg" :icon="['fa', 'th-list']" /></span>
                    </span>
                    <span 
                        :class="[display === 'card' ? activeClass : inactiveClass]"
                        class="flex justify-center border rounded-md cursor-pointer py-2 px-3 ml-1"
                        @click="changeDisplay('card')"
                    >
                        <span><font-awesome-icon class="text-lg" :icon="['fa', 'th']" /></span>
                    </span>
                </div>
            </div>
            <div class="flex flex-wrap justify-between items-end mb-5">
                <div class="flex items-end">
                    <DefaultForm
                        v-model:keyword="form.keyword"
                        v-model:archived="form.archived"
                        @search="search()"
                        placeholder="Libelle, Ville, Code postal"
                        class="mt-3"
                    />
                    <FilterItem
                        class="ml-3"
                        @handleUpdateList="handleUpdateList()"
                        @resetFilter="unsetFilter()"
                        v-model:keyword="form.keyword"
                        v-model:scis="form.scis"
                        v-model:types="form.types"
                        v-model:archived="form.archived"
                        v-model:ownerType="form.ownerType"
                        v-model:displayImmeubleAppartement="form.displayImmeubleAppartement"
                        :disableScisFilter="sciUuid !== undefined"
                        :disableBiensFilter="bienUuid !== undefined"
                    />
                </div>
                <div v-if="!bienUuid && !sciUuid">
                    <button class="btn btn-primary" @click="$refs.addBienModal.openModal()">
                        <font-awesome-icon class="text-lg mr-2" :icon="['fa', 'plus']" /> Ajouter
                    </button>
                    <AddBienModal ref="addBienModal"/>
                </div>
                <div v-else-if="(bienUuid || sciUuid) && !title" class="flex">
                    <span
                        :class="[display === 'table' ? activeClass : inactiveClass]"
                        class="flex justify-center border rounded-md cursor-pointer py-2 px-3"
                        @click="changeDisplay('table')"
                    >
                        <span><font-awesome-icon class="text-lg" :icon="['fa', 'th-list']" /></span>
                    </span>
                    <span
                        :class="[display === 'card' ? activeClass : inactiveClass]"
                        class="flex justify-center border rounded-md cursor-pointer py-2 px-3 ml-1"
                        @click="changeDisplay('card')"
                    >
                        <span><font-awesome-icon class="text-lg" :icon="['fa', 'th']" /></span>
                    </span>
                </div>
            </div>

            <ListItem 
                v-if="loaded" 
                :biens="biens.results" 
                @reset-filter="resetFilter()" 
                :display="display"
            />
            <SpinnerLoader v-else></SpinnerLoader>

            <PaginationBlock v-if="biens && biens.total > 0" :paginator="biens" @changePage="changePage"/>
        </div>
    </div>
</template>

<script>

import { toRefs, ref, inject, onMounted } from 'vue'
import SpinnerLoader from './../../loader/SpinnerLoader';
import ListItem from './ListItem';
import FilterItem from './FilterItem';
import PaginationBlock from './../../composant/PaginationBlock'
import { useRoute } from 'vue-router';
import AddBienModal from '../../modal/bien/AddBienModal.vue';
import DefaultForm from '../../form-group/DefaultForm';

export default {
    components: {
        SpinnerLoader,
        ListItem,
        FilterItem,
        PaginationBlock,
        AddBienModal,
        DefaultForm
    },
    props: {
        title: {
            type: String,
            default: null
        },
        sciUuid: String,
        bienUuid: String
    },
    setup(props) {
        const loaded = ref(false);
        const biens = ref([]);
        const route = useRoute()
        const api = inject('api');
        const formServices = inject('formServices');
        const tokenServices = inject('tokenServices');
        const activeClass = ref("text-gray-700  border-gray-300 bg-gray-100");
        const inactiveClass = ref("text-gray-500  border-gray-100 hover:text-gray-700 hover:border-gray-300 hover:bg-gray-100");

        let display = ref(tokenServices.getUserSettingFromKey('display_bien_mode'));

        let form = ref();

        if (route.name === 'bien') {
            form.value = formServices.getForm('list_bien_form')
        } else {
            form.value = formServices.getListBienForm();
        }

        if (props.sciUuid) {
            form.value.scis.push(props.sciUuid)
            form.value.ownerType = 'sci'
        }

        if (props.bienUuid) {
            form.value.ownerType = 'all'
            form.value.displayImmeubleAppartement = 'only'
            form.value.types.push('appartement')

            const {bienUuid} = toRefs(props)
            form.value.immeubleUuid = bienUuid
        }

        onMounted(async () => {
            await loadBiens();
        });

        function unsetFilter() {
            formServices.resetForm('list_bien_form')
            form.value = formServices.getListBienForm();

            if (props.sciUuid) {
                form.value.scis.push(props.sciUuid)
                form.value.ownerType = 'sci'
            }

            if (props.bienUuid) {
                form.value.ownerType = 'all'
                form.value.displayImmeubleAppartement = 'only'
                form.value.types.push('appartement')

                const {bienUuid} = toRefs(props)
                form.value.immeubleUuid = bienUuid
            }
        }

        async function resetFilter() {
            unsetFilter()

            await loadBiens()
        }

        async function changePage(page) {
            await loadBiens(page)
        }

        async function changeDisplay(newVal) {
            display.value = newVal
            
            await api.user.updateSettings('display_bien_mode', newVal)
        }

        async function search() {
            await loadBiens()
        }

        async function handleUpdateList() {
            await loadBiens()
        }

        async function loadBiens(page = 1) {
            loaded.value = false

            if (route.name === 'bien') {
                formServices.updateForm('list_bien_form', form.value)
            }

            biens.value = await api.bien.getBiens(page, form.value);

            loaded.value = true;
        }

        return {loaded, biens, changePage, form, loadBiens, unsetFilter, resetFilter, activeClass, inactiveClass, changeDisplay, display, search, handleUpdateList}
    }
};
</script>