<template>
    <PrimaryModal
        title="Ajouter une SCI"
        ref="addSciModal"
        btnSuccessText="Ajouter"
        :disabledBtn="checkFieldValid"
        @ok="addSci()"
        :width="750"
    >
        <template v-slot:content>
            <div class="md:col-span-3">
                <AppInput 
                    v-model='sci.libelle' 
                    class="mt-3" 
                    title='Libelle' 
                    id='libelle' 
                    :validations="['required']"
                    @hasError="handleFormError"
                    :check-on-create="false"
                />
                <div class="grid grid-cols-1 md:grid-cols-3">
                    <AppInput 
                        v-model='sci.capital' 
                        class="mt-5 md:pr-2" 
                        title='Capital (en €)' 
                        id='capital'
                        :validations="['integer']"
                        @hasError="handleFormError"/>
                    <AppInput v-model='sci.rcs_numeros' class="mt-5 md:pr-2" title='Numéros RCS' id='rcs_num'/>
                    <AppInput v-model='sci.rcs_ville' class="mt-5" title='Ville du RCS' id='rcs_ville'/>
                </div>
                <AppInput v-model='sci.adresse' class="mt-5" title='Adresse' id='adresse'/>
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <AppInput v-model='sci.cp' class="mt-5 md:pr-2" title='Code postal' id='cp' placeholder="ex: 49100"/>
                    <AppInput v-model='sci.ville' class="mt-5" title='Ville' id='ville'  placeholder="ex: Angers"/>
                </div>
            </div>
        </template>
    </PrimaryModal>
</template>

<script>

import AppInput from './../../form/AppInput';
import PrimaryModal from './../../composant/PrimaryModal';

export default {
    components: {
        PrimaryModal,
        AppInput
    },
    data() {
        return {
            sci: {
                libelle: ""
            },
            formInvalid: []
        }
    },
    computed: {
        checkFieldValid() {
            return this.formInvalid.length !== 0 || this.sci.libelle.length === 0;
        }
    },
    methods: {
        openModal() {
            this.$refs.addSciModal.openModal();
        },
        async addSci() {
            if (this.checkFieldValid) {
                return;
            }

            this.$refs.addSciModal.activateLoader();

            const result = await this.$api.sci.addSci( {
                libelle: this.sci.libelle,
                capital: parseInt(this.sci.capital),
                rcs_ville: this.sci.rcs_ville,
                rcs_numeros: this.sci.rcs_numeros,
                adresse: this.sci.adresse,
                cp: this.sci.cp,
                ville: this.sci.ville
            })
            .then((result) => {
                this.$toast({title: 'Succès', description: "La SCI à été créée avec succès."}, this.$toastSuccess);

                return Promise.resolve(result)
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.addSciModal.deactivateLoader();
            this.$router.push({ name: 'sciDetails', params: { sciUuid: result.uuid} })
        },
        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },
    }
};
</script>