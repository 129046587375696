<template>
    <div>
        <div class="flex justify-between">
            <h1 class="text-3xl text-bold">Profil</h1>
            <LoaderButton title="Enregistrer" @ok="handleUpdateUser" :disabled="checkFieldValid" ref="btnValidate"></LoaderButton>
        </div>
        <div class="flex flex-wrap rounded">
            <div class="w-full md:w-1/2 mb-6 px-2 md:mb-0 mt-4">
                <AppInput
                    v-model='prenomState'
                    title='Prénom'
                    id='prenom'
                    :validations="['required']"
                    @hasError="handleFormError"
                />
            </div>
            <div class="w-full md:w-1/2 mt-4 px-2">
                <AppInput v-model='nomState' title='Nom' id='nom' :validations="['required']" @hasError="handleFormError"/>
            </div>
        </div>
        <div class="flex flex-wrap bg-white rounded">
            <div class="w-full md:w-1/2 mt-4 px-2">
                <AppInput v-model='emailState' title='Email' id='email' :disabled='true'/>
            </div>
            <div class="w-full md:w-1/2 mt-4 px-2">
                <AppInput v-model='telephoneState' title='Téléphone' id='telephone'/>
            </div>
        </div>
        <div class="flex flex-wrap bg-white rounded">
            <div class="w-full md:w-2/4 mt-4 px-2 md:mb-0">
                <AppInput v-model='adresseState' title='Adresse' id='adresse'/>
            </div>
            <div class="w-full md:w-1/4 mt-4 px-2 md:mb-0">
                <AppInput v-model='villeState' title='Ville' id='ville'/>
            </div>
            <div class="w-full md:w-1/4 mt-4 px-2">
                <AppInput v-model='cpState' title='Code postal' id='codePostal'/>
            </div>
        </div>
    </div>
</template>

<script>

import LoaderButton from '../form/LoaderButton';
import AppInput from '../form/AppInput';
import { useVModel } from '../composables/useVModel'

export default {
    components: {
        LoaderButton,
        AppInput,
    },
    data() {
        return {
            formInvalid: [],
        }
    },
    computed: {
        checkFieldValid() {
            return this.formInvalid.length !== 0;
        }
    },
    methods: {
        async handleUpdateUser() {
            if (this.checkFieldValid) {
                return;
            }

            this.$refs.btnValidate.activateLoader();

            await this.$api.user.updateUser(this.uuid, {
                nom: this.nom,
                prenom: this.prenom,
                telephone: this.telephone,
                adresse: this.adresse,
                cp: this.cp,
                ville: this.ville,
            })
            .then(() => {
                this.$toast({title: 'Succès', description: "L'utilisateur à bien été enregistré."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.btnValidate.deactivateLoader();
        },
        openChangePasswordModal() {
            this.$refs.changePasswordModal.openModal();
        },
        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        }
    },
    emits: [
        'update:nom',
        'update:prenom',
        'update:email',
        'update:telephone',
        'update:adresse',
        'update:cp',
        'update:ville',
        'update:uuid'
    ],
    props: {
        nom: String, 
        prenom: String, 
        telephone: String, 
        email: String, 
        adresse: String,
        cp: String,
        ville: String,
        uuid: String,
    },
    setup(props) {
        return {
            nomState: useVModel(props, 'nom'),
            prenomState: useVModel(props, 'prenom'),
            emailState: useVModel(props, 'email'),
            telephoneState: useVModel(props, 'telephone'),
            adresseState: useVModel(props, 'adresse'),
            cpState: useVModel(props, 'cp'),
            villeState: useVModel(props, 'ville'),
            uuidState: useVModel(props, 'uuid'),
        }
    },
};
</script>