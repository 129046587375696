<template>
    <div v-if="scis && scis.length !== 0">
        <div class="my-5" v-for="(sci, index) in scis" :key="index">
            <hr>
            <div class="flex justify-between justify-items-center items-center mt-4">
                <div>
                    <router-link
                        :to="`/sci/${sci.uuid}`"
                    >
                        <h3 class="text-indigo-500 cursor-pointer hover:text-indigo-700 inline-block text-lg">{{sci.libelle}}</h3>
                    </router-link> <span v-if="sci.rcs_numeros">- </span><span class="text-sm text-gray-400">{{sci.rcs_numeros}}</span>
                    <p class="text-gray-600 text-sm">{{sci.adresse}}</p>
                    <p class="text-gray-600 text-sm">{{ sci.cp }} <span v-if="sci.cp && sci.ville">- </span> {{ sci.ville }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5" v-else>
        <hr>
        <div class="flex flex-col items-center justify-center mt-5">
            <p class="text-sm text-indigo-400">Aucune SCI n'a été trouvé.</p>
            <p @click="$emit('resetFilter')" class="text-sm text-indigo-600 cursor-pointer hover:text-indigo-300 mt-2">
                <font-awesome-icon class="text-lg" :icon="['fa', 'eraser']" />
                Réinitialiser les filtres.
            </p>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        scis: {
            type: Array,
            default() {
                return []
            }
        },
    }
};
</script>