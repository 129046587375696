<template>
    <div>
        <div class="bg-white rounded-xl p-6">
            <div>
                <h1 class="text-2xl text-bold">Mes Sci</h1>
            </div>
            <div class="flex flex-wrap justify-between items-center mb-5 mt-3">
                <DefaultForm
                    v-model:keyword="form.libelle"
                    v-model:archived="form.archived"
                    @search="loadScis()"
                    placeholder="Libelle"
                    class="mt-3"
                />
                <div class="mt-3">
                    <button class="btn btn-primary" @click="$refs.addSciModal.openModal()">
                        <font-awesome-icon class="text-lg mr-2" :icon="['fa', 'plus']" /> Ajouter
                    </button>
                    <AddSciModal ref="addSciModal"/>
                </div>
            </div>
            <ListItem v-if="loaded" :scis="scis.results" @reset-filter="resetFilter()"/>
            <SpinnerLoader v-else></SpinnerLoader>
            <PaginationBlock v-if="scis && scis.total > 0" :paginator="scis" @changePage="changePage"/>
        </div>
    </div>
</template>

<script>

import { ref, inject, onMounted } from 'vue'
import SpinnerLoader from './../components/loader/SpinnerLoader';
import ListItem from './../components/sci/list/ListItem';
import PaginationBlock from './../components/composant/PaginationBlock';
import AddSciModal from '../components/modal/sci/AddSciModal';
import DefaultForm from '../components/form-group/DefaultForm';

export default {
    components: {
        SpinnerLoader,
        ListItem,
        PaginationBlock,
        AddSciModal,
        DefaultForm
    },

    setup() {
        const loaded = ref(false);
        const scis = ref([]);
        const api = inject('api');
        const formServices = inject('formServices');
        let form = ref();

        form.value = formServices.getForm('list_sci_form')

        onMounted(async () => {
            await loadScis();
        });

        async function changePage(page) {
            await loadScis(page)
        }

        async function loadScis(page = 1) {
            loaded.value = false

            formServices.updateForm('list_sci_form', form.value)
            scis.value = await api.sci.getScis(page, form.value);

            loaded.value = true;
        }

        return {loaded, scis, changePage, form, loadScis}
    }
};
</script>