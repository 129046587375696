<template>
    <div class="mt-3">
        <div class="flex justify-between items-center bg-indigo-600 rounded-lg py-3 cursor-pointer px-3" @click="openExpense = !openExpense">
            <h2 class="text-white text-lg">Dépense</h2>
            <span class="text-sm text-white">
                <font-awesome-icon v-if="openExpense" :icon="['fa', 'chevron-down']" />
                <font-awesome-icon v-else :icon="['fa', 'chevron-right']" />
            </span>
        </div>
        
        <div v-if="openExpense">
            <PaginationBlock v-if="paginator && paginator.total > 0" :paginator="paginator" @changePage="changePage"/>
            <table class="w-full border-t-2 border-gray-600">
                <thead>
                    <tr class="">
                        <th scope="col" class="text-left py-3 px-2 text-sm font-normal rtl:text-right text-gray-500 dark:text-gray-400">
                            Libelle
                        </th>
                        <th scope="col" class="text-left py-3 px-2 text-sm font-normal rtl:text-right text-gray-500 dark:text-gray-400">
                            Montant
                        </th>
                        <th scope="col" class="text-left py-3 px-2 text-sm font-normal rtl:text-right text-gray-500 dark:text-gray-400">
                            Date de paiement
                        </th>
                        <th scope="col" class="text-left py-3.5 px-2 flex justify-end items-center">
                            <button class="btn btn-primary" @click="addNewExpense = true">
                                <font-awesome-icon class="text-md" :icon="['fa', 'plus']" />
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        v-if="addNewExpense"
                        class="border-t border-indigo-300"
                    >
                        <td class="px-2 py-3 text-sm font-medium whitespace-nowrap text-gray-700">
                            <AppInput 
                                v-model='form.libelle' 
                                id='libelle'
                                :validations="['required']"
                                @hasError="handleFormError"
                                :check-on-create="false"
                                :check-on-unfocus="true"
                            />
                        </td>
                        <td class="px-2 py-3 text-sm font-medium whitespace-nowrap text-gray-700">
                            <AppInput 
                                v-model='form.amount'
                                id='amount'
                                :validations="['number', 'required']"
                                @hasError="handleFormError"
                                :check-on-create="false"
                                :check-on-unfocus="true"
                                icon="euro-sign"
                            />
                        </td>
                        <td class="px-2 py-3 text-sm font-medium text-gray-700">
                            <DatePicker 
                                v-model='form.paiementDate' 
                                class="mt-3 mr-2"
                                id='paiementDate' 
                            />
                        </td>
                        <td class="px-2 py-3 text-sm whitespace-nowrap">
                            <div class="flex justify-end items-center">
                                <button class="btn-secondary mr-1" @click="addNewExpense = false">
                                    <font-awesome-icon class="text-sm" :icon="['fa', 'undo']" />
                                </button>
                                <button class="btn-success" :class="checkFieldValid ? '' : 'cursor-not-allowed bg-green-300 border-green-300 hover:bg-green-300 hover:border-green-300'" @click="addExpense()">
                                    <font-awesome-icon v-if="addLoader" class="text-sm animate-spin" :icon="['fas', 'spinner']"  />
                                    <font-awesome-icon v-else class="text-sm" :icon="['fa', 'check']" />
                                </button>
                            </div>
                        </td>
                    </tr>
                    <template
                        v-if="paginator && paginator.results && paginator.results.length !== 0"
                    >
                        <template
                            v-for="expense of paginator.results" :key="expense.uuid"
                        >
                            <ExpenseItem :expense="expense" @refresh="loadExpense" />
                        </template>

                    </template>
                    <tr
                        class="px-2 py-3 border-t border-gray-200"
                        v-else-if="!loaded"
                    >
                        <td colspan="5">
                            <SpinnerLoader class="mt-5"></SpinnerLoader>
                        </td>
                    </tr>
                    <tr
                        class="px-2 py-3 border-t border-gray-200"
                        v-else-if="!addNewExpense"
                    >
                        <td colspan="5" class="text-center">
                            <p class="text-sm text-indigo-400 my-4">Aucune dépense foncière n'a été trouvé.</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import SpinnerLoader from './../../../loader/SpinnerLoader';
import PaginationBlock from './../../../composant/PaginationBlock';
import AppInput from './../../../form/AppInput';
import DatePicker from './../../../form/DatePicker';
import ExpenseItem from './../../../bien/details/financials/ExpenseItem';
export default {
    components: {
        SpinnerLoader,
        PaginationBlock,
        AppInput,
        DatePicker,
        ExpenseItem
    },

    data() {
        return {
            paginator: {
                results: []
            },
            loaded: false,
            addNewExpense: false,
            addLoader: false,
            form: {
                libelle: '',
                amount: null,
                paiementDate: null
            },
            formInvalid: [],
            openExpense: false,
        }
    },

    created() {
        this.loadExpense()
    },

    computed: {
        checkFieldValid() {
            return this.formInvalid.length === 0 && this.form.libelle.length !== 0 && this.form.amount;
        }
    },

    methods: {
        async changePage(page) {
            await this.loadExpense(page)
        },

        async loadExpense(page = 1) {
            this.loaded = false

            this.paginator = await this.$api.bien.getExpenses(page, this.$route.params.bienUuid);

            this.loaded = true
        },

        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },

        async addExpense() {
            if (!this.checkFieldValid) {
                return;
            }

            this.addLoader = true

            await this.$api.bien.addExpense(this.$route.params.bienUuid, {
                libelle: this.form.libelle,
                amount: this.form.amount,
                paiement_date: this.form.paiementDate
            })
            .then(async () => {
                this.$toast({title: 'Succès', description: "La dépense à bien été enregistré."}, this.$toastSuccess);
                this.form = {
                    libelle: new Date().getFullYear(),
                    amount: null,
                    paiementDate: null
                }
                await this.loadExpense()
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.addNewExpense = false
            this.addLoader = false
        },
    }
};
</script>
