class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("user"));

      return user?.refreshToken;
    }
  
    getLocalToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.token;
    }
  
    updateLocalToken(token) {
      let user = JSON.parse(localStorage.getItem("user"));
      user.token = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem("user"));
    }
  
    async setUser(user) {
      await localStorage.setItem("user", JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem("user");
    }

    getUserSettingFromKey(key) {
      const user = this.getUser();

      if (user.settings && user.settings[key]) {
        return user.settings[key]
      }

      return ''
    }

    updateSettings(key, value) {
      let user = JSON.parse(localStorage.getItem("user"));
      user.settings[key] = value;
      localStorage.setItem("user", JSON.stringify(user));
    }
  }
  
  export default new TokenService();