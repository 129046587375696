<template>
    <tr :class="loyer.new ? 'bg-indigo-100 rounded-md' : 'border-t border-indigo-300'">
        <td class="px-2 py-2 whitespace-nowrap text-gray-700">
            <p class="flex items-center">
                {{ formatMonth(loyer.month) }} {{ loyer.year }}
                <span v-if="loyer.new" class="ml-2 bg-indigo-600 text-white rounded-lg px-2 py-1 text-xs">New</span>
            </p>
            <p v-if="loyer.paiement_date" class="text-indigo-400 italic text-xs">
                Payé le {{ formatDate(loyer.paiement_date) }}
            </p>
        </td>
        <td class="px-2 py-2 whitespace-nowrap text-gray-700">
            <p>
                {{ loyer.loyer+loyer.charges }} €
            </p>
            <p v-if="loyer.charges" class="text-indigo-400 italic text-xs">
                {{ loyer.loyer }}  + {{ loyer.charges }} € de charges
            </p>
        </td>
        <td class="px-2 py-2 text-sm text-gray-700 flex flex-col">
            <HabitantRel :habitant="habitant" :locataireUuid="habitant?.locataire?.uuid" v-for="habitant in loyer.habitants" :key="habitant.uuid" class="mt-1"/>
        </td>
        <td class="px-2 py-2 text-sm whitespace-nowrap">
            <span
                class="flex items-center justify-end"
            >
                <button v-if="loyer.new" class="btn-success mr-1 px-3" @click.prevent="openValidateLoyerModal(loyer.uuid);">
                    <font-awesome-icon class="text-sm" :icon="['fa', 'check']" />
                </button>
                <button class="btn-secondary mr-1 px-3" @click="$refs.editLoyerModal.openModal(loyer)">
                    <font-awesome-icon class="text-sm" :icon="['fa', 'edit']" />
                </button>
                <button class="btn-danger" @click.prevent="openRemoveLoyerModal(loyer.uuid);">
                    <font-awesome-icon class="text-sm text-white" :icon="['fa', 'times']" />
                </button>
            </span>
        </td>
    </tr>
    <EditLoyerModal
        ref="editLoyerModal"
        @handleUpdateList="$emit('refresh')"
    />
    <PrimaryModal
        title="Valider le loyer"
        ref="validateLoyerModal"
        btnSuccessText="Valider"
        classButton="btn-success"
        @ok="validateLoyer()"
    >
        <template v-slot:content>
            <p class="text-sm">
                Voulez-vous vraiment valider ce loyer créé automatiquement et l'intégrer.
            </p>
        </template>
    </PrimaryModal>
    <PrimaryModal
        title="Supprimer le loyer"
        ref="removeLoyerModal"
        btnSuccessText="Supprimer"
        classButton="btn-danger"
        @ok="removeLoyer()"
    >
        <template v-slot:content>
            <p class="text-sm">
                <span class="font-bold text-md text-red-600"><font-awesome-icon :icon="['fa', 'exclamation-circle']" /> Attention</span>
                Vous êtes sur le point de supprimer ce loyer. Cette action est irréversible, en êtes-vous sûr ?
            </p>
        </template>
    </PrimaryModal>
</template>

<script>

import HabitantRel from './../../../composant/habitant/HabitantRel.vue';
import PrimaryModal from './../../../composant/PrimaryModal';
import EditLoyerModal from './../../../modal/bien/financials/loyer/EditLoyerModal';

export default {
    components: {
        HabitantRel,
        PrimaryModal,
        EditLoyerModal
    },

    emits: ['refresh'],

    data() {
        return {
            openLoyer: false,
            loyerUuid: null,
            editLoyerData: {}
        }
    },

    props: {
        loyer: {
            type: Object,
            default() {
                return {}
            }
        },
    },

    methods: {
        openValidateLoyerModal(loyerUuid) {
            this.loyerUuid = loyerUuid
            this.$refs.validateLoyerModal.openModal()
        },

        openRemoveLoyerModal(loyerUuid) {
            this.loyerUuid = loyerUuid
            this.$refs.removeLoyerModal.openModal()
        },

        async removeLoyer() {
            if (!this.$route.params.bienUuid || !this.loyerUuid) {
                return;
            }

            await this.$api.bien.removeLoyer(this.$route.params.bienUuid, this.loyerUuid)
            .then(async () => {
                this.$toast({title: 'Succès', description: "Le loyer à bien été supprimé."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$emit('refresh')
            this.$refs.removeLoyerModal.dismissModal()
        },

        async validateLoyer() {
            if (!this.$route.params.bienUuid || !this.loyerUuid) {
                return;
            }

            await this.$api.bien.validateLoyer(this.$route.params.bienUuid, this.loyerUuid)
            .then(async () => {
                this.$toast({title: 'Succès', description: "Le loyer à bien été validé."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$emit('refresh')
            this.$refs.validateLoyerModal.dismissModal()
        }
    }
};
</script>
