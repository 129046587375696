<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8 bg-white p-6 rounded-xl shadow-xl">
            <div>
                <img src="@/assets/img/logo.svg" alt="Logo gesti" class="mx-auto h-12 w-auto"/>
                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Connectez-vous à votre compte
                </h2>
            </div>
            <div class="mt-8 space-y-6" action="#" method="POST">
                <input type="hidden" name="remember" value="true" />
                <form 
                    class="rounded-md shadow-sm -space-y-px"
                >
                    <div>
                        <label for="email-address" class="sr-only">Email</label>
                        <input 
                            id="email-address"
                            v-model="username"
                            name="email"
                            type="email"
                            autocomplete="email"
                            required=""
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            :class="formError ? 'border-red-300' : 'border-gray-300'"
                            placeholder="Email"
                            @keyup.enter="login"
                        />
                    </div>
                    <div>
                        <label for="password" class="sr-only">Mot de passe</label>
                        <input 
                            id="password"
                            v-model="password"
                            name="password"
                            type="password"
                            autocomplete="current-password"
                            required=""
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            :class="formError ? 'border-red-300' : 'border-gray-300'"
                            placeholder="Mot de passe"
                            @keyup.enter="login"
                        />
                    </div>
                </form>

                <div class="flex justify-end">
                    <div class="text-sm">
                        <button 
                            class="font-medium text-indigo-600 hover:text-indigo-500"
                            @click='openPasswordForgiveModal()'
                        >
                            Mot de passe oublié ?
                        </button>
                    </div>
                </div>
                <div>
                    <LoaderButton title="Se connecter" @ok="login" defaultClass="w-full btn-primary" ref="btnValidate"></LoaderButton>
                </div>
            </div>
        </div>
    </div>
    <PrimaryModal
        title="Réinitialiser votre mot de passe"
        btnSuccessText="Envoyer"
        ref="forgetPasswordModal"
        @ok="sendResetEmail"
    >
        <template v-slot:content>
            <input 
                v-model="passwordForgiveEmail"
                type="email"
                name="email"
                class="appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                :class="passwordForgiveEmailError ? 'border-red-500' : 'border-gray-300'"
            />
            <p 
                v-if="passwordForgiveEmailError"
                class='text-red-500 text-xs'
            >Cette valeur n'est pas une adresse email valide.</p>
        </template>
    </PrimaryModal>
</template>

<script>
import PrimaryModal from './../../components/composant/PrimaryModal';
import LoaderButton from './../../components/form/LoaderButton';

export default {
    components: {
        PrimaryModal,
        LoaderButton
    },
    data () {
        return {
            username: '',
            password: '',
            passwordForgiveEmail: '',
            passwordForgiveEmailError: false,
            submitted: false,
            formError: false,
            loader: false,
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        checkCredentials() {
            return !this.username || !this.password
        }
    },
    created() {
        if (this.loggedIn) {
            this.$router.push({ name: 'dashboard'});
        }
    },
    methods: {
        async login() {
            if (this.checkCredentials) {
                return;
            }

            this.$clearToast();
            this.$refs.btnValidate.activateLoader();
            try {
                this.formError = false

                await this.$store.dispatch('auth/login', {
                    username: this.username,
                    password: this.password,
                }).catch((err) => {
                    this.$toast({title: 'Erreur', description: err}, this.$toastError);
                    this.formError = true
                });
            } catch (e) {
                console.log(e)
            }

            this.$refs.btnValidate.deactivateLoader();
        },
        openPasswordForgiveModal() {
            this.$refs.forgetPasswordModal.openModal();
            this.passwordForgiveEmail = this.username;
        },
        async sendResetEmail() {
            this.passwordForgiveEmailError = false;
            
            if (!this.$validator.validateEmail(this.passwordForgiveEmail)) {
                this.passwordForgiveEmailError = true;
                return;
            }

            await this.$api.auth.resetPasswordRequest(this.passwordForgiveEmail)
            .then(() => {
                this.$toast({
                    title: 'Succès',
                    description: "Un email de réinitialisation a été envoyé à l'adresse renseignée."
                }, this.$toastSuccess)

                this.$refs.forgetPasswordModal.dismissModal();
            })
            .catch(() => {
                this.$toast({
                    title: 'Erreur',
                    description: "Une erreur inatendu est survenu, veuillez réessayer plus tard. Contacter un administrateur si le problème persiste"
                }, this.$toastError)

                return;
            })
        }
    }
};
</script>
