<template>
    <div>
        <div class="border-b flex justify-between border-gray-400 dark:border-gray-700">
            <ul v-if="returnRoute" class="flex justify-end flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                <li class="mr-2">
                    <a href="#" class="tabs" @click.prevent="goToLastRoute()"><font-awesome-icon class="mr-2" :icon="['fa', 'arrow-left']"/> {{ returnText}}</a>
                </li>
            </ul>
            <ul v-else></ul>
            <ul class="flex justify-end flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                <template v-for="(tab) in tabs" :key="tab.name">
                    <li class="mr-2">
                        <a href="#" :class="active(tab.name) ? 'tabs-active' : 'tabs'" @click.prevent="select(tab.name)"><font-awesome-icon class="mr-2" :icon="['fa', tab.icon || '']"/> {{ tab.label }}</a>
                    </li>
                </template>
            </ul>
        </div>
        <hr>
    </div>
</template>

<script>

export default {
    props: {
        tabs: {
            type: Array, 
            default: () => {
                return [];
            }
        },
        modelValue: {
            type: String
        },
        returnRoute: {
            type: String,
            default: null
        },
        routeParams: {
            type: Object,
            default: () => {
                return {};
            }
        },
        returnText: {
            type: String,
            default: 'Retour'
        }
    },
    methods: {
        active(index) {
            return index === this.modelValue
        },
        select(tab) {
            this.$emit('input', tab)
        },
        goToLastRoute() {
            this.$router.push({ name: this.returnRoute, params: this.routeParams });
        }
    }
};
</script>
