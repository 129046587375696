<template>
    <div>
        <div class="bg-white rounded-xl p-6">
            <div>
                <h1 class="text-2xl text-bold">Mes Locataires</h1>
            </div>
            <div class="flex justify-between items-center mb-5 mt-3">
                <DefaultForm
                    v-model:keyword="form.keyword"
                    @search="loadLocataires()"
                    :disableArchived="true"
                    placeholder="Email, Nom, Prénom"
                    class="mt-3"
                />
            </div>
            <ListItem v-if="loaded" :locataires="locataires.results" @reset-filter="resetFilter()"/>
            <SpinnerLoader v-else></SpinnerLoader>
            <PaginationBlock v-if="locataires && locataires.total > 0" :paginator="locataires" @changePage="changePage"/>
        </div>
    </div>
</template>

<script>


import { ref, inject, onMounted } from 'vue'
import SpinnerLoader from './../components/loader/SpinnerLoader';
import ListItem from './../components/locataire/list/ListItem';
import PaginationBlock from './../components/composant/PaginationBlock'
import DefaultForm from './../components/form-group/DefaultForm';

export default {
    components: {
        SpinnerLoader,
        ListItem,
        PaginationBlock,
        DefaultForm
    },
    setup() {
        const loaded = ref(false);
        const locataires = ref([]);
        const api = inject('api');
        const formServices = inject('formServices');
        let form = ref();

        form.value = formServices.getForm('list_locataire_form')

        onMounted(async () => {
            await loadLocataires();
        });

        function unsetFilter() {
            formServices.resetForm('list_locataire_form')
            form.value = formServices.getListLocataireForm();
        }

        async function changePage(page) {
            await loadLocataires(page)
        }

        async function loadLocataires(page = 1) {
            loaded.value = false

            formServices.updateForm('list_locataire_form', form.value)
            locataires.value = await api.locataire.getLocataires(page, form.value);

            loaded.value = true;
        }

        return {loaded, locataires, changePage, unsetFilter, form, loadLocataires}
    }
};
</script>