<template>
    <BiensItem
        class="mt-2"
        :bienUuid="bienUuid"
    />
</template>

<script>

import BiensItem from './../../components/bien/list/BiensItem';

export default {
    components: {
        BiensItem
    },
    data() {
        return {
            bienUuid: null
        }
    },
    created() {
        this.bienUuid = this.$route.params.bienUuid
    }
};
</script>