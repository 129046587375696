<template>
    <tr class=" border-t border-indigo-300">
        <td class="px-2 py-4 text-sm font-medium whitespace-nowrap text-gray-700">
            <span v-if="edit">
                <AppInput 
                    v-model='form.year' 
                    id='year'
                    :validations="['year', 'required']"
                    @hasError="handleFormError"
                    :check-on-unfocus="true"
                />
            </span>
            <span v-else>
                {{ taxeFonciere.year }}
            </span>
        </td>
        <td class="px-2 py-4 text-sm font-medium whitespace-nowrap text-gray-700">
            <span v-if="edit">
                <AppInput 
                    v-model='form.amount'
                    id='amount'
                    :validations="['number', 'required']"
                    @hasError="handleFormError"
                    :check-on-create="false"
                    :check-on-unfocus="true"
                    icon="euro-sign"
                />
            </span>
            <span v-else>
                {{ taxeFonciere.amount }} €
            </span>
        </td>
        <td class="px-2 py-4 text-sm font-medium text-gray-700">
            <div v-if="edit">
                <DatePicker 
                    v-model='form.paiementDate' 
                    class="mt-3 mr-2"
                    id='paiementDate' 
                />
            </div>
            <span v-else>
                {{ formatDate(form.paiementDate) }}
            </span>
        </td>
        <td class="px-2 py-4 text-sm whitespace-nowrap">
            <span 
                v-if="!edit"
                class="flex items-center justify-end"
            >
                <button class="btn-secondary mr-1 px-3" @click="edit = true">
                    <font-awesome-icon class="text-sm" :icon="['fa', 'edit']" />
                </button>
                <button class="btn-danger" @click.prevent="openRemoveTaxeFonciereModal(taxeFonciere.uuid);">
                    <font-awesome-icon class="text-sm text-white" :icon="['fa', 'times']" />
                </button>
            </span>
            <span 
                v-else
                class="flex items-center justify-end"
            >
                <button class="btn-secondary mr-1" @click="edit = false">
                    <font-awesome-icon class="text-md" :icon="['fa', 'undo']" />
                </button>
                <button class="btn-success" :class="checkFieldValid ? '' : 'cursor-not-allowed bg-green-300 border-green-300 hover:bg-green-300 hover:border-green-300'" @click="editTaxeFonciere()">
                    <font-awesome-icon v-if="editLoader" class="text-sm animate-spin" :icon="['fas', 'spinner']"  />
                    <font-awesome-icon v-else class="text-sm" :icon="['fa', 'check']" />
                </button>
            </span>
        </td>
    </tr>
    <PrimaryModal
        title="Supprimer la taxe foncière"
        ref="removeTaxeFonciereModal"
        btnSuccessText="Supprimer"
        classButton="btn-danger"
        @ok="removeTaxeFonciere()"
    >
        <template v-slot:content>
            <p class="text-sm">
                <span class="font-bold text-md text-red-600"><font-awesome-icon :icon="['fa', 'exclamation-circle']" /> Attention</span>
                Vous êtes sur le point de supprimer cette taxe foncière. Cette action est irréversible, en êtes-vous sûr ?
            </p>
        </template>
    </PrimaryModal>                
</template>

<script>

import AppInput from './../../../form/AppInput';
import PrimaryModal from './../../../composant/PrimaryModal';
import DatePicker from './../../../form/DatePicker';
export default {
    components: {
        AppInput,
        PrimaryModal,
        DatePicker
    },

    emits: ['refresh'],

    data() {
        return {
            edit: false,
            openTaxeFonciere: false,
            taxeFonciereUuid: null,
            form: {},
            formInvalid: [],
            editLoader: false
        }
    },

    props: {
        taxeFonciere: {
            type: Object,
            default() {
                return {}
            }
        },
    },

    created() {
        this.form = this.taxeFonciere
        this.form.paiementDate = this.taxeFonciere.paiement_date
    },

    computed: {
        checkFieldValid() {
            return this.formInvalid.length === 0 && this.form.year.length !== 0 && this.form.amount;
        }
    },

    methods: {
        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },

        openRemoveTaxeFonciereModal(taxeFonciereUuid) {
            this.taxeFonciereUuid = taxeFonciereUuid
            this.$refs.removeTaxeFonciereModal.openModal()
        },

        async removeTaxeFonciere() {
            if (!this.$route.params.bienUuid || !this.taxeFonciereUuid) {
                return;
            }

            await this.$api.bien.removeTaxeFonciere(this.$route.params.bienUuid, this.taxeFonciereUuid)
            .then(async () => {
                this.$toast({title: 'Succès', description: "La taxe foncière à bien été supprimé."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$emit('refresh')
            this.$refs.removeTaxeFonciereModal.dismissModal()
        },

        async editTaxeFonciere() {
            if (!this.$route.params.bienUuid || !this.form.uuid || !this.checkFieldValid) {
                return;
            }

            this.editLoader = true

            await this.$api.bien.updateTaxeFonciere(this.$route.params.bienUuid, this.form.uuid, {
                year: this.form.year && this.form.year.toString(),
                amount: this.form.amount,
                paiement_date: this.form.paiementDate
            })
            .then(async () => {
                this.$toast({title: 'Succès', description: "La taxe foncière à bien été enregistré."}, this.$toastSuccess);
                this.$emit('refresh')
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.editLoader = false
            this.edit = false
        }
    }
};
</script>
