<template>
<div>
    <label class="form-label" :for="id">
        {{title}}{{ validations.includes('required') ? '*' : ''}}
    </label>
    <div
        v-if="!disabled"
    >
        <DatePicker
            :id="id"
            :value="modelValue"
            @selected="inputChange"
            @close="$emit('onClose')"
            language="fr"
            :clear-button="clearBtn"
            :minimum-view="minimumType"
            :maximum-view="maximumType"
            @changedMonth="handleChangeMonth"
            @changedYear="handleChangedYear"
            :inline="inline"
        >
        </DatePicker>
        <span v-if="icon" class="absolute inset-y-0 right-0 flex items-center px-2">
            <font-awesome-icon class="text-md text-gray-500 z-10" :icon="['fa', icon]" />
        </span>
    </div>
    <div v-else class="form-control bg-gray-200">{{ modelValue }}</div>
    <p v-if="hasError" class="text-red-500 text-xs italic">{{ displayError }}</p>
</div>
</template>

<script>

import validator from '../../common/utils/Validator';
import DatePicker from 'vuejs3-datepicker';

export default {
    components: {
        DatePicker
    },

    data() {
        return {
            validationError: ''
        }
    },

    props: {
        modelValue: [Date, String, Number],
        title: String,
        id: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: String,
        validations: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: null
        },
        checkOnCreate: {
            type: Boolean,
            default: true
        },
        icon: String,
        maximumType: {
            type: String,
            default: 'day'
        },
        minimumType: {
            type: String,
            default: 'day'
        },
        inline: {
            type: Boolean,
            default: false,
        },
        clearBtn: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        hasError() {
            return !!(this.error || this.validationError);
        },

        displayError() {
            if (this.error) {
                return this.error;
            }

            if (this.validationError) {
                return this.validationError;
            }

            return '';
        }
    },

    methods: {
        inputChange(event) {
            this.$emit('update:modelValue', event);
            this.checkValidations(event);
        },

        checkValidations(value) {
            this.validationError = '';

            if (value && this.validations.includes('required') && value.length === 0) {
                this.validationError = validator.requiredMessage();
                return;
            }

            if (
                value &&
                this.validations.includes('integer') &&
                value.length !== 0 &&
                !validator.isOnlyNumber(value)
            ) {
                this.validationError = validator.onlyIntegerMessage();
                return;
            }

            if (
                value &&
                this.validations.includes('number') &&
                value.length !== 0 &&
                !validator.isNumber(value)
            ) {
                this.validationError = validator.onlyNumberMessage();
                return;
            }
        },

        handleChangedYear(value) {
            this.$emit('changeYear', value.year)
        },

        handleChangeMonth(value) {
            this.$emit('changeMonth', value.month)
        }
    },

    watch: {
        hasError(newValue) {
            this.$emit('hasError', {name: this.id, value: newValue});
        }
    }
};
</script>