import auth from './auth.service';
import user from './user.service';
import bien from './bien.service';
import sci from './sci.service';
import habitant from './habitant.service';
import locataire from './locataire.service';

// without default
export default {
  auth,
  user,
  bien,
  sci,
  habitant,
  locataire
}