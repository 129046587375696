<template>
    <PrimaryModal
        title="Ajouter un Bien"
        ref="addBienModal"
        btnSuccessText="Ajouter"
        :disabledBtn="checkFieldValid"
        @ok="addBien()"
        :width="750"
    >
        <template v-slot:content>
            <div class="grid grid-cols-6 gap-4">
                <div class="col-span-2 flex items-center">
                    <h3 class="text-2xl bold">Propriétaire*</h3>
                </div>
                <div class="col-span-2 flex items-center">
                    <SwitchForm v-model='proprietaire' labelLeft="Nom Propre" labelRight="SCI" id='proprietaire'/>
                </div>
                <div class="col-span-2 flex items-center" v-if="proprietaire">
                    <multiselect
                        placeholder=""
                        v-model="sci"
                        :options="scis"
                        :loading="loadScis"
                        @search-change="searchScis"
                        track-by="id"
                        label="libelle"
                        selectLabel=""
                        deselectLabel=""
                        selectedLabel="Séléctionné"
                        @select="updateSci"
                    >
                        <template v-slot:noOptions>Rechercher une SCI.</template>
                        <template v-slot:noResult>Aucune SCI trouvée.</template>
                    </multiselect>
                </div>
                <div v-else></div>
            </div>
            <div class="grid grid-cols-7 gap-4 border-t border-gray-300 pt-4 mt-4">
                <div class="col-span-7 md:col-span-2">
                    <RadioButton v-model="bien.type" :options="typesOptions" name="owner" :hideLabel="true" :displayBlock="true"/>
                </div>
                <div class="col-span-5 md:border-l border-gray-300 px-2 flex flex-wrap">
                    <template v-if="bien.type === 'appartement'">
                        <div class="w-full px-2 md:mb-4">
                            <label class="form-label" for="immeubleUuid">Immeuble</label>
                            <multiselect
                                placeholder="Vous pouvez rattacher l'appartement à un immeuble"
                                v-model="immeuble"
                                :options="immeubles"
                                :loading="loadImmeubles"
                                @search-change="searchImmeubles"
                                track-by="id"
                                label="libelle"
                                selectLabel=""
                                deselectLabel=""
                                selectedLabel="Séléctionné"
                                @select="updateImmeuble"
                                @remove="removeImmeuble"
                            >
                                <template v-slot:noOptions>Rechercher un immeuble.</template>
                                <template v-slot:noResult>Aucun immeuble trouvé.</template>
                            </multiselect>
                        </div>
                    </template>
                    <div class="w-full px-2 md:mb-0">
                        <AppInput 
                            v-model='bien.libelle'
                            title='Libelle' 
                            id='libelle'
                            :validations="['required']"
                            @hasError="handleFormError"
                            :check-on-create="false"
                        />
                    </div>
                    <div class="w-full px-2 md:mb-0 mt-4">
                        <AppInput v-model='bien.adresse' title='Adresse' id='adresse'/>
                    </div>
                    <div class="w-full md:w-2/4 mt-4 px-2 md:mb-0">
                        <AppInput v-model='bien.ville' title='Ville' id='ville'/>
                    </div>
                    <div class="w-full md:w-2/4 mt-4 px-2">
                        <AppInput v-model='bien.cp' title='Code postal' id='codePostal'/>
                    </div>
                    <div class="flex flex-wrap border-t border-gray-200 mt-4">
                        <div class="w-full md:w-2/6 px-2 md:mb-0 mt-4">
                            <AppInput 
                                v-model='bien.loyer' 
                                title='Loyer' 
                                id='loyer'
                                :validations="['number']"
                                @hasError="handleFormError"
                                :check-on-create="false"
                                icon="euro-sign"
                            />
                        </div>
                        <div class="w-full md:w-2/6 mt-4 px-2 md:mb-0">
                            <AppInput 
                                v-model='bien.charges' 
                                title='Charges' 
                                id='charges'
                                :validations="['number']"
                                @hasError="handleFormError"
                                :check-on-create="false"
                                icon="euro-sign"
                            />
                        </div>
                        <div class="w-full md:w-2/6 mt-4 px-2 md:mb-0">
                            <label class="form-label" for="paiementDay">
                                Jour de paiement
                            </label>
                            <div
                                class="relative"
                            >
                                <Multiselect
                                    v-model='bien.paiement_day'
                                    id='paiementDay'
                                    :options="paiementDayOptions"
                                    v-bind="$multiselectBind"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer>
            <div class="flex justify-between">

            </div>
        </template>
    </PrimaryModal>
</template>

<script>

import PrimaryModal from './../../composant/PrimaryModal';
import RadioButton from '../../form/RadioButton';
import AppInput from '../../form/AppInput';
import SwitchForm from '../../form/SwitchForm';
import Multiselect from 'vue-multiselect'
import { mapGetters } from "vuex";

export default {
    components: {
        PrimaryModal,
        RadioButton,
        AppInput,
        SwitchForm,
        Multiselect
    },
    data() {
        return {
            proprietaire: 0,
            bien: {
                owner: "",
                type: "appartement",
                libelle: "",
                adresse: null,
                cp: null,
                ville: null,
                loyer: null,
                charges: null,
                immeubleUuid: null,
            },
            formInvalid: [],
            stepperTabs: [],
            index: 'owner',
            typesOptions: [
                {id: 'appartement', libelle: 'Appartement'},
                {id: 'maison', libelle: 'Maison'},
                {id: 'immeuble', libelle: 'Immeuble'},
            ],
            sci: null,
            scis: [],
            loadScis: false,
            immeuble: null,
            immeubles: [],
            loadImmeubles: false,
        }
    },

    async created() {
        if (!this.$store.state.auth.user || !this.$store.state.auth.user.uuid) {
            this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            this.$router.push({ name: 'dashboard'});
        }

        this.bien.owner = this.$store.state.auth.user.uuid
    },

    computed: {
        checkFieldValid() {
            return this.formInvalid.length !== 0 || this.bien.libelle.length === 0 || this.bien.owner === '';
        },
        ...mapGetters({
            paiementDayOptions: 'getDayOptions',
        }),
    },

    methods: {
        openModal() {
            this.$refs.addBienModal.openModal();
        },
        async searchScis(query) {
            this.loadScis = true

            const { results } = await this.$api.sci.getScis(1, {libelle: query});

            this.scis = results.map(s => {
                return {
                    id: s.uuid,
                    libelle: s.libelle
                }
            })

            this.loadScis = false
        },
        async searchImmeubles(query) {
            this.loadImmeubles = true
            const params = {libelle: query, types: ['immeuble']}

            if (this.proprietaire) {
                params.ownerType = 'sci'
                if (this.bien.owner) {
                    params.scis = [this.bien.owner]
                }
            } else {
                params.ownerType = 'perso'
            }
            const { results } = await this.$api.bien.getBiens(1, params);

            this.immeubles = results.map(s => {
                return {
                    id: s.uuid,
                    libelle: s.libelle
                }
            })

            this.loadImmeubles = false
        },
        async updateSci(selectedOption) {
            this.bien.owner = selectedOption.id
            this.immeubles = []
            this.immeuble = null
            this.bien.immeubleUuid = null
        },
        async updateImmeuble(selectedOption) {
            this.bien.immeubleUuid = selectedOption.id
        },
        async removeImmeuble() {
            this.bien.immeubleUuid = null
        },
        async addBien() {
            if (this.checkFieldValid) {
                return;
            }

            this.$refs.addBienModal.activateLoader();

            const data = {                
                owner: this.bien.owner,
                type: this.bien.type,
                libelle: this.bien.libelle,
                adresse: this.bien.adresse,
                cp: this.bien.cp,
                ville: this.bien.ville,
                paiement_day: this.bien.paiement_day && this.bien.paiement_day.length !== 0 ? this.bien.paiement_day : null,
            }

            if (this.bien.loyer) {
                data.loyer = parseFloat(this.bien.loyer)
            }

            if (this.bien.charges) {
                data.charges = parseFloat(this.bien.charges)
            }

            if (this.bien.type === 'appartement') {
                data.immeubleUuid = this.bien.immeubleUuid
            }

            const {uuid} = await this.$api.bien.addBien(data)
            .then((result) => {
                this.$toast({title: 'Succès', description: "Le Bien à été créé avec succès."}, this.$toastSuccess);

                return Promise.resolve(result)
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.addBienModal.deactivateLoader();
            this.$router.push({ name: 'bienDetails', params: { bienUuid: uuid} })
        },
        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },
    },

    watch: {
        proprietaire(newVal) {
            if (newVal) {
                this.bien.owner = this.sci ? this.sci.id : ''
                this.immeubles = []
                this.immeuble = null
                this.bien.immeubleUuid = null
            } else {
                this.bien.owner = this.$store.state.auth.user.uuid
                this.immeubles = []
                this.immeuble = null
                this.bien.immeubleUuid = null
            }
        },
        'bien.type'() {
            if ('appartement' === this.bien.type && this.immeuble) {
                this.bien.immeubleUuid = this.immeuble.id
            } else {
                this.bien.immeubleUuid = null
            }
        }
    }
};
</script>

<style>

.show-enter-active,
.show-leave-enter {
    transform: translateX(0);
    transition: all .3s linear;
}
.show-enter,
.show-leave-to {
    transform: translateX(100%);
}
</style>