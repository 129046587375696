import api from "./api";
import { catchValidationErrors } from "./catchErrors";

class SciService {
    async getScis(page, form = {}) {
        const { data } = await api.get(`/api/sci?page=${page}`, { params: form }).catch(catchValidationErrors);

        return data;
    }

    async getSci(sci_uuid) {
        const { data } = await api.get(`/api/sci/${sci_uuid}`).catch(catchValidationErrors);

        return data;
    }

    async addSci(form) {
        const { data } = await api.post(`/api/sci`, {...form}).catch(catchValidationErrors);

        return data;
    }

    async updateSci(sci_uuid, form) {
        return await api.put(`/api/sci/${sci_uuid}`, {...form}).catch(catchValidationErrors);
    }

    async archiveSci(sci_uuid) {
        return await api.patch(`/api/sci/${sci_uuid}/archived`).catch(catchValidationErrors);
    }

    async unarchiveSci(sci_uuid) {
        return await api.patch(`/api/sci/${sci_uuid}/unarchived`).catch(catchValidationErrors);
    }

    async removeSci(sci_uuid) {
        return await api.delete(`/api/sci/${sci_uuid}`).catch(catchValidationErrors);
    }
}

export default new SciService();