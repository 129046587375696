<template>
    <button type="submit" :class="btnClass" @click="handleValidate">
        <div></div>
        <font-awesome-icon
            :icon="['fa', icon]"
            v-if="icon"
            class="mr-1"
        />
        <p class="px-1">{{ title }}</p>
        <font-awesome-icon 
            :icon="['fas', 'spinner']" 
            class="animate-spin"
            v-if="loader"
        />
        <div v-else></div>
    </button>
</template>

<script>
export default {
    data() {
        return {    
            loader: false,
        }
    },
    props: {
        title: {
            type: String,
            default: 'Valider'
        },
        defaultClass: {
            type: String,
            default: 'btn-primary'
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: undefined
        }
    },
    emits: ['ok'],
    methods: {
        activateLoader() {
            this.loader = true;
        },
        deactivateLoader() {
            this.loader = false;
        },
        handleValidate() {
            if (!this.loader) {
                this.$emit('ok');
            }
        }
    },
    computed: {
        btnClass() {
            if (this.disabled) {
                return `${this.defaultClass} opacity-50 cursor-not-allowed`
            }

            return this.defaultClass;
        }
    }
};
</script>