<template>
    <div>
        <div class="bg-white rounded-xl p-6">
            <ProfilItem
                v-if="loaded"
                v-model:nom="form.nom"
                v-model:prenom="form.prenom"
                v-model:email="form.email"
                v-model:telephone="form.telephone"
                v-model:adresse="form.adresse"
                v-model:cp="form.cp"
                v-model:ville="form.ville"
                v-model:uuid="form.uuid"
            />
            <SpinnerLoader v-else></SpinnerLoader>
        </div>
        <div class="flex flex-wrap mt-6">
            <div class="w-full md:w-1/2 bg-white rounded-xl p-6">
                <SecurityItem v-if="loaded" :userUuid="form.uuid"/>
                <SpinnerLoader v-else></SpinnerLoader>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, inject, onMounted } from 'vue'
import ProfilItem from './../components/profil/ProfilItem';
import SecurityItem from './../components/profil/SecurityItem';
import SpinnerLoader from './../components/loader/SpinnerLoader';

export default {
    components: {
        ProfilItem,
        SecurityItem,
        SpinnerLoader
    },

    setup() {
        const loaded = ref(false);
        const form = ref({
            nom: '',
            prenom: '',
            email: '',
            telephone: '',
            adresse: '',
            cp: '',
            ville: '',
            uuid: '',
        });

        onMounted(async () => {
            const api = inject('api');
            const result = await api.user.getCurrentUser();

            form.value.nom = result.nom
            form.value.prenom = result.prenom
            form.value.email = result.email
            form.value.telephone = result.telephone
            form.value.adresse = result.adresse
            form.value.cp = result.cp
            form.value.ville = result.ville
            form.value.uuid = result.uuid

            loaded.value = true;
        });

        return {form, loaded}
    },
};
</script>