<template>
  <div class="flex h-screen bg-gray-200 font-roboto">
    <SidebarMenu :isOpen="isOpen" @closeMenu="closeMenu"/>

    <div class="flex-1 flex flex-col overflow-hidden">
      <HeaderMenu :isOpen="isOpen" @openMenu="openMenu" />

      <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
        <div class="container mx-auto px-6 py-8">
        
          <router-view/>
        </div>
      </main>
    </div>
  </div>

</template>

<script>
import HeaderMenu from "./../menu/HeaderMenu";
import SidebarMenu from "./../menu/SidebarMenu";

export default {
    components: {
        HeaderMenu,
        SidebarMenu,
    },
    data() {
        return {
            isOpen: false,
        }
    },
    methods: {
        closeMenu() {
            this.isOpen = false;
        },
        openMenu(){
            this.isOpen = true;
        }
    },
    watch: {
        $route() {
            this.isOpen = false;
        }
    }
}
</script>