<template>
    <div  v-if="loaded" class="grid grid-cols-1 md:grid-cols-5 mt-3">
        <div class="md:col-span-5 pr-5">
            <div class="bg-indigo-50 border border-indigo-600 rounded-md p-4 flex justify-between items-center">
                <h4 v-if="!edit" class="flex text-indigo-700 mt-3">
                    <div class="mr-2">
                        <user-icon :user="locataire"/>
                    </div>
                    <div>
                        {{ locataire.prenom }} {{ locataire.nom }}
                        <a class="block text-gray-500 text-sm hover:text-indigo-700" :href="`mailto:${locataire.email}`">{{ locataire.email }}</a>
                        <p class="text-indigo-400 text-sm">{{ locataire.telephone }}</p>
                    </div>
                </h4>
                <div v-else class="flex">
                    <AppInput v-model='locataireData.prenom' title='Prénom' id='prenom' :validations="['required']" @hasError="handleFormError" class="mr-2"/>
                    <AppInput v-model='locataireData.nom' title='Nom' id='nom' :validations="['required']" @hasError="handleFormError" class="mr-2"/>
                    <AppInput v-model='locataireData.telephone' title='Téléphone' id='telephone' @hasError="handleFormError" class="mr-2"/>
                </div>

                <div>
                    <span v-if="edit" class="border rounded-lg py-2 px-3 text-gray-100 cursor-pointer bg-indigo-800" @click="edit = false">
                        <font-awesome-icon class="text-sm" :icon="['fa', 'undo']" />
                    </span>

                    <span class="border rounded-lg  py-2 px-3 text-gray-100" :class="!checkFieldValid ? 'cursor-pointer bg-indigo-800' : 'cursor-not-allowed bg-indigo-300'" @click="editInfo()">
                        <font-awesome-icon v-if="edit === false" class="text-sm" :icon="['fa', 'edit']" />
                        <font-awesome-icon v-else-if="edit" class="text-sm" :icon="['fa', 'check']" />
                        <font-awesome-icon v-else class="text-sm animate-spin" :icon="['fas', 'spinner']"  />
                    </span>
                </div>
            </div>
            <div 
                class="mt-4  p-3 border rounded-md" 
                :class="item.archived ? 'bg-gray-100 border border-gray-400' : 'bg-yellow-100 border-yellow-400'"
                v-for="(item, index) in locataire.habitants" :key="index"
            >
                <div class="grid grid-cols-3">
                    <div class="">
                        <router-link class="text-indigo-500 hover:text-indigo-800" :to="`/bien/${item.bien.uuid}/details`">
                            {{ item.bien.libelle }}
                        </router-link>
                        <div class="flex items-center">
                            <span>{{ formatDate(item.date_entree) }}</span>
                            <span v-if="item.date_sortie" class="flex items-center">
                                <font-awesome-icon class="text-sm mx-1" :icon="['fas', 'arrow-right']"  />
                                {{ formatDate(item.date_sortie) }}
                            </span>
                        </div>
                    </div>
                    <div>
                        <p class="text-gray-500 text-sm">Dépot de garantie: {{ item.security_deposit }}€</p>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
    <SpinnerLoader v-else class="mt-5"></SpinnerLoader>
</template>

<script>
import { inject } from 'vue'
import AppInput from './../../components/form/AppInput';
import SpinnerLoader from './../../components/loader/SpinnerLoader';

export default {
    components: {
        SpinnerLoader,
        AppInput
    },
    data() {
        return {
            locataire: {},
            loaded: false,
            formInvalid: [],
            edit: false,
            locataireData: {}
        }
    },
    computed: {
        checkFieldValid() {
            return this.formInvalid.length !== 0;
        }
    },
    created() {
        this.loadLocataire()
    },
    methods: {
        async loadLocataire() {
            const api = inject('api');

            this.loaded = false
            this.locataire = await api.locataire.getLocataire(this.$route.params.locataireUuid);
            this.loaded = true
        },

        showRole(item) {
            if(item.admin) {
                return 
            }
        },

        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },

        async editInfo() {
            if (this.edit === false) {
                this.locataireData = {
                    prenom: this.locataire.prenom,
                    nom: this.locataire.nom,
                    telephone: this.locataire.telephone,
                }
                this.edit = true

                return;
            }

            if (this.checkFieldValid) {
                return;
            }

            this.edit = undefined

            this.locataire = await this.$api.locataire.updateLocataire(this.locataire.uuid, {
                nom: this.locataireData.nom,
                prenom: this.locataireData.prenom,
                telephone: this.locataireData.telephone,
            })
            .then((data) => {
                this.$toast({title: 'Succès', description: "Le locataire à bien été enregistré."}, this.$toastSuccess);
                
                return data
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.edit = false
        }
    },
};
</script>