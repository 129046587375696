<template>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div class="">
            <div class="flex mt-4 mb-2">
                <LoaderButton title="Enregistrer" @ok="handleUpdateBienSettings" ref="btnUpdateSettings"></LoaderButton>
            </div>
            <hr>
            <SwitchForm class="mt-3" v-model='bien.auto_loyer' labelRight="Loyer automatique" id='autoLoyer'/>
            <div 
                class="flex flex-wrap items-center mt-2"
                :class="bien.auto_loyer_at ? 'text-indigo-500' : formError.includes('autoLoyerError') ? 'text-red-500' : 'text-yellow-500'"
                v-if="bien.auto_loyer"
            >
                <p>Créer un paiement automatique tous les </p>
                <Multiselect
                    v-model='bien.auto_loyer_at'
                    id='autoLoyerAt'
                    :options="autoLoyerAtOptions"
                    v-bind="$multiselectBind"
                    placeholder=""
                    class="mx-3"
                    style="max-width: 5rem;"
                    selectedLabel=""
                />
                <p class="text-wrap"> de chaque mois</p>
            </div>
        </div>
        <div>
            <div v-if="bien">
                <div v-if="!bien.archived" class="mt-4 bg-yellow-100 border-l-4 p-5 border-yellow-400 font-medium">
                    <h4 class="text-gray-800">Archiver ce bien</h4>
                    <p class="text-sm text-gray-600">Cette action retirera le bien de votre liste, vous pourrez toujours y accéder pour consulter les informations.</p>
                    <button class="btn-warning mt-2" @click.prevent="$refs.archiveBienModal.openModal();">
                        Archiver
                    </button>
                    <PrimaryModal
                        title="Archiver le bien"
                        ref="archiveBienModal"
                        btnSuccessText="Archiver"
                        classButton="btn-warning"
                        @ok="archiveBien()"
                    >
                        <template v-slot:content>
                            <p class="text-sm">Êtes-vous sûr de vouloir archiver ce bien. Cette action peut être réversible dans la section paramètre.</p>
                        </template>
                    </PrimaryModal>
                </div>
                <div v-else class="mt-4 bg-blue-100 border-l-4 p-5 border-blue-400 font-medium">
                    <h4 class="text-gray-800">Désarchiver ce bien</h4>
                    <p class="text-sm text-gray-600">Cette action retirera le bien des archives.</p>
                    <button class="btn-info mt-2" @click.prevent="$refs.unarchiveBienModal.openModal();">
                        Désarchiver
                    </button>
                    <PrimaryModal
                        title="Désarchiver le bien"
                        ref="unarchiveBienModal"
                        btnSuccessText="Désarchiver"
                        classButton="btn-info"
                        @ok="unarchiveBien()"
                    >
                        <template v-slot:content>
                            <p class="text-sm">Désarchiver ce bien.</p>
                        </template>
                    </PrimaryModal>
                </div>
            </div>
            <SpinnerLoader v-else class="mt-5"></SpinnerLoader>
            <div class="mt-4 bg-red-100 border-l-4 p-5 border-red-400 font-medium">
                <h4 class="text-gray-800">Supprimer ce bien</h4>
                <p class="text-sm text-gray-400">Cette action supprimera complètement le bien. Elle est irréversible.</p>
                <button class="btn-danger mt-2" @click.prevent="$refs.removeBienModal.openModal();">
                    Supprimer
                </button>
                <PrimaryModal
                    title="Supprimer le bien"
                    ref="removeBienModal"
                    btnSuccessText="Supprimer"
                    classButton="btn-danger"
                    @ok="removeBien()"
                >
                    <template v-slot:content>
                        <p class="text-sm">
                            <span class="font-bold text-md text-red-600"><font-awesome-icon :icon="['fa', 'exclamation-circle']" /> Attention</span> 
                            Vous êtes sur le point de supprimer ce bien. Cette action est irréversible, en êtes-vous sûr ?</p>
                    </template>
                </PrimaryModal>
            </div>
        </div>
    </div>
</template>

<script>

import LoaderButton from './../../components/form/LoaderButton';
import SwitchForm from './../../components/form/SwitchForm';
import PrimaryModal from './../../components/composant/PrimaryModal';
import SpinnerLoader from './../../components/loader/SpinnerLoader';
import Multiselect from 'vue-multiselect'
import { inject } from 'vue'
import { mapGetters } from "vuex";

export default {
    components: {
        PrimaryModal,
        SpinnerLoader,
        SwitchForm,
        Multiselect,
        LoaderButton
    },
    data() {
        return {
            bien: {
                auto_loyer: 0,
                auto_loyer_at: ''
            },
            loaded: false,
            formError: []
        }
    },
    created() {
        this.loadBien()
    },
    computed: {
        ...mapGetters({
            autoLoyerAtOptions: 'getDayOptions',
        }),
    },
    methods: {
        async loadBien() {
            const api = inject('api');

            this.loaded = false

            this.bien = await api.bien.getBien(this.$route.params.bienUuid);

            if (this.bien.auto_loyer === null) {
                this.bien.auto_loyer = 0
            }

            this.loaded = true
        },
        async archiveBien() {
            if (this.bien.archived || !this.bien.uuid) {
                return;
            }

            this.$refs.archiveBienModal.activateLoader();

            await this.$api.bien.archiveBien(this.bien.uuid)
            .then(() => {
                this.$toast({title: 'Succès', description: "Le bien à été archivé avec succès."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.archiveBienModal.deactivateLoader();
            this.$router.push({ name: 'bien' })
        },
        async removeBien() {
            if (!this.$route.params.bienUuid) {
                return;
            }

            this.$refs.removeBienModal.activateLoader();

            await this.$api.bien.removeBien(this.$route.params.bienUuid)
            .then(() => {
                this.$toast({title: 'Succès', description: "Le bien à été supprimé avec succès."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.removeBienModal.deactivateLoader();
            this.$router.push({ name: 'bien' })
        },
        async unarchiveBien() {
            if (!this.bien.archived || !this.bien.uuid) {
                return;
            }

            this.$refs.unarchiveBienModal.activateLoader();

            await this.$api.bien.unarchiveBien(this.bien.uuid)
            .then(() => {
                this.$toast({title: 'Succès', description: "Le bien à été désarchivé avec succès."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.unarchiveBienModal.deactivateLoader();
            this.$router.push({ name: 'bien' })
        },
        async handleUpdateBienSettings() {
            if (!this.checkFieldValid()) {
                return;
            }

            this.$refs.btnUpdateSettings.activateLoader();

            await this.$api.bien.updateAutoLoyer(this.bien.uuid, {
                auto_loyer: this.bien.auto_loyer ? true : false,
                auto_loyer_at: this.bien.auto_loyer_at,
            })
            .then(() => {
                this.$toast({title: 'Succès', description: "Les paramètres du bien ont bien été enregistré."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.btnUpdateSettings.deactivateLoader();
        },
        checkFieldValid() {
            this.formError = []

            if (this.bien.auto_loyer && !this.bien.auto_loyer_at) {
                this.formError.push('autoLoyerError')
            }

            return ((this.bien.auto_loyer && this.bien.auto_loyer_at) || !this.bien.auto_loyer) && this.bien.uuid
        }
    },
};
</script>