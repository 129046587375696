<template>
    <div class="my-5" v-for="(bien, index) in biens" :key="index">
        <hr>
        <div class="flex justify-between justify-items-center items-center mt-4">
            <div>
                <router-link  :to="`/bien/${bien.uuid}`">
                    <h3 class="text-indigo-500 cursor-pointer hover:text-indigo-700 inline-block text-lg">{{bien.libelle}}</h3>

                    <div class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                        <p>{{ bien.ville }}<template v-if="bien.ville && bien.cp"> - </template>{{ bien.cp }}</p>
                        <p>{{ bien.adresse }}</p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        biens: {
            type: Array,
            default() {
                return []
            }
        },
    },
};
</script>