<template>
    <BiensItem
        title="Mes Biens"
    />
</template>

<script>

import BiensItem from './../components/bien/list/BiensItem';

export default {
    components: {
        BiensItem
    },
};
</script>