<template>
    <div class="bg-white rounded-xl p-6">
        <TabsItem 
            v-model="activeTab" 
            :tabs="tabs" 
            :return-route="returnRoute" 
            :route-params="routeParams" 
            @input="changeTab"
            :return-text="returnText"
        />
        <router-view></router-view>
    </div>
</template>

<script>

import TabsItem from './../components/composant/TabsItem';

export default {
    components: {
        TabsItem
    },
    data() {
        return {
            activeTab: "bienDetailsDetails",
            tabs: [],
            lastUuid: null,
            returnRoute: "bien",
            routeParams: [],
            returnText: "Retour"
        }
    },
    created() {
        this.loadRoute(this.$route.name);
    },

    methods: {
        async loadRoute(currentRoute) {
            if (
                ['bienDetails', 'bienDetailsDetails', 'bienDetailsLocataires', 'bienDetailsFinancials', 'bienDetailsBiens', 'bienDetailsSettings'].indexOf(currentRoute) >= 0 &&
                (null === this.lastUuid || this.lastUuid !== this.$route.params.bienUuid)
            ) {
                this.tabs = []
                this.returnRoute = "bien"
                this.returnText = "Retour"
                
                this.tabs.push({ label: "Détails", name: "bienDetailsDetails", icon: "book"})

                const {type, immeuble} = await this.$api.bien.getBien(this.$route.params.bienUuid);

                if (type === 'Immeuble') {
                    this.tabs.push({ label: "Biens", name: "bienDetailsBiens", icon: "home"})
                }

                if (immeuble && immeuble.uuid) {
                    this.returnRoute = 'bienDetailsBiens'
                    this.routeParams = { bienUuid: immeuble.uuid }
                    this.returnText = "Retour vers l'immeuble"
                }

                this.tabs.push({ label: "Locataires", name: "bienDetailsLocataires", icon: "users"})
                this.tabs.push({ label: "Finances", name: "bienDetailsFinancials", icon: "money-bill"})
                this.tabs.push({ label: "Paramètres", name: "bienDetailsSettings", icon: "cog"})
                this.lastUuid = this.$route.params.bienUuid
            }

            if (currentRoute === "bienDetails") {
                this.$router.push({name: this.tabs[0].name})
            }

            this.activeTab = currentRoute
        },
        changeTab(routeName) {
            this.$router.push({ name: routeName });
        },
    },
    watch: {
        $route(current) {
            this.loadRoute(current.name)
        }
    }
};
</script>