<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8 bg-white p-6 rounded-xl shadow-xl">
            <div>
                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Réinitialiser votre mot de passe
                </h2>
            </div>
            <div class="mt-8 space-y-6">
                <div class="-space-y-px">
                    <div>
                        <label for="password" class="sr-only">Nouveau mot de passe</label>
                        <input 
                            v-model="password"
                            name="password"
                            type="password"
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Nouveau mot de passe"
                            @input="checkStrength()"
                            @keyup.enter="handleNewPassword"
                        />
                    </div>
                    <div class="flex justify-start -mx-1 pt-3 pl-4">
                        <ul class="text-left text-sm">
                            <li :class="formSuccess.minimal ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.minimal" :icon="['fas', formSuccess.minimal ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                8 charactères minimum
                            </li>
                            <li :class="formSuccess.number ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.number" :icon="['fas', formSuccess.number ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                Un chiffre minimum
                            </li>
                            <li :class="formSuccess.letter ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.letter" :icon="['fas', formSuccess.letter ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                Une lettre minimum
                            </li>
                            <li :class="formSuccess.case ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.case" :icon="['fas', formSuccess.case ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                Au moins une majuscule et une minuscule
                            </li>
                            <li :class="formSuccess.special ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.special" :icon="['fas', formSuccess.special ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                Un charactère spécial
                            </li>
                        </ul>
                    </div>
                    <div class="pt-8">
                        <label for="retypePassword" class="sr-only">Répéter le mot de passe</label>
                        <input 
                            v-model="retypePassword"
                            name="retypePassword"
                            type="password"
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Répéter le mot de passe"
                            @input="checkStrength()"
                            @keyup.enter="handleNewPassword()"
                        />
                    </div>
                    <div class="flex justify-start -mx-1 pt-3 pl-4">
                        <ul class="text-left text-sm">
                            <li :class="formSuccess.same ? 'text-green-400' : 'text-gray-400'">
                                <transition name="slide-fade" mode="out-in">
                                    <font-awesome-icon :key="formSuccess.same" :icon="['fas', formSuccess.same ? 'check-circle' : 'minus-circle']" />
                                </transition>
                                Les mots de passe ne sont pas identique
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <button 
                        type="submit" 
                        class="group relative w-full flex justify-between py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        :class="!checkStrength() ? 'opacity-50 cursor-not-allowed' : ''"
                        @click="handleNewPassword()"
                        :disabled="!checkStrength() || loader"
                    >
                        <div></div>
                        Changer le mot de passe
                        <font-awesome-icon 
                            :icon="['fas', 'spinner']" 
                            class="animate-spin mt-1"
                            v-if="loader"
                        />
                        <div v-else></div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data () {
        return {
            password: '',
            retypePassword: '',
            formSuccess: {
                minimal: false,
                number: false,
                letter: false,
                case: false,
                special: false,
                same: false,
            },
            loader: false,
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    async mounted() {
        if (this.loggedIn) {
            this.$router.push({ name: 'dashboard'});
        }

        await this.$api.auth.checkResetPasswordToken(this.$route.query.token).catch(() => {
            this.$toast({
                title: 'Erreur', 
                description: 'Votre jeton est introuvable ou expiré, veuillez recommencer',
            }, this.$toastError)

            this.$router.push({ name: 'Login'});
        })
    },
    methods: {
        checkStrength() {
            this.formSuccess.minimal = this.password.length >= 8;
            this.formSuccess.number = this.$validator.hasNumber(this.password);
            this.formSuccess.letter = this.$validator.hasLetter(this.password);
            this.formSuccess.case = this.$validator.hasCaseDiff(this.password);
            this.formSuccess.special = this.$validator.hasSpecialChar(this.password);
            this.formSuccess.same = this.password && this.retypePassword && this.password === this.retypePassword;

            return this.formSuccess.minimal && 
                this.formSuccess.number && 
                this.formSuccess.letter && 
                this.formSuccess.case &&
                this.formSuccess.special && 
                this.formSuccess.same
        },
        async handleNewPassword() {
            if (!this.checkStrength()) {
                return;
            }

            this.loader = true;
            await this.$api.auth.resetPassword(this.$route.query.token, this.password)
                .then(() => {
                    this.$toast({
                        title: 'Succès', 
                        description: 'Votre mot de passe à bien été modifié.',
                    }, this.$toastSuccess)
                    this.$router.push({ name: 'Login'});
                })
                .catch(() => {
                    this.$toast({
                        title: 'Erreur', 
                        description: 'Une erreur est survenu, veuillez réessayer plus tard.',
                    }, this.$toastError)
                });
        }
    }
};
</script>
<style> 
.slide-fade-enter-active {
  transition: transform 0.5s ease-in-out;
}
.slide-fade-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: rotateZ(360deg);
}
</style>