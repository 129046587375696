<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
      :class="isOpen ? 'block' : 'hidden'"
      @click="$emit('closeMenu')"
      class="fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden"
    ></div>
    <!-- End Backdrop -->

    <div
      :class="isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
      class="fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 transform bg-gray-900 lg:translate-x-0 lg:static lg:inset-0"
    >
      <div class="flex items-center ml-4 mt-8">
        <router-link class="flex items-center" to="/dashboard">
          <img src="@/assets/img/logo.svg" alt="Logo gesti" class="mx-auto h-10 w-auto"/>
          <span class="mx-3 text-2xl font-semibold text-white"
            >Gesti</span
          >
        </router-link>
      </div>

      <nav class="mt-10">
        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 border-l-4"
          :class="[$route.name === 'dashboard' ? activeClass : inactiveClass]"
          to="/dashboard"
        >
          <font-awesome-icon :icon="['fas', 'chart-pie']" class="w-5 h-5"/>

          <span class="mx-4">Dashboard</span>
        </router-link>
        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 border-l-4"
          :class="[['bien', 'bienDetails', 'bienDetailsDetails', 'bienDetailsLocataires', 'bienDetailsFinancials', 'bienDetailsBiens', 'bienDetailsSettings'].includes($route.name) ? activeClass : inactiveClass]"
          to="/bien"
        >
          <font-awesome-icon :icon="['fa', 'home']" class="w-5 h-5"/>

          <span class="mx-4">Mes Biens</span>
        </router-link>
        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 border-l-4"
          :class="[['locataire', 'locataireDetails', 'locataireDetailsDetails'].includes($route.name) ? activeClass : inactiveClass]"
          to="/locataires"
        >
          <font-awesome-icon :icon="['fa', 'users']" class="w-5 h-5"/>

          <span class="mx-4">Mes Locataires</span>
        </router-link>
        <router-link
          class="flex items-center px-6 py-2 mt-4 duration-200 border-l-4"
          :class="[['sci', 'sciDetails', 'sciDetailsDetails', 'sciDetailsBiens', 'sciDetailsSettings'].includes($route.name) ? activeClass : inactiveClass]"
          to="/sci"
        >
          <font-awesome-icon :icon="['fa', 'briefcase']" class="w-5 h-5"/>

          <span class="mx-4">Mes Sci</span>
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            activeClass: "bg-gray-600 bg-opacity-25 text-gray-100 border-gray-100",
            inactiveClass: "border-gray-900 text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100"
        }
    },
    emits: ['closeMenu'],
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
    },
};
</script>
