<template>
    <div class="">
        <span 
            class="flex justify-center border rounded-md cursor-pointer py-2 px-3 hover:text-gray-700 hover:border-gray-300 hover:bg-gray-100"
            @click="openFilter = true"
        >
            <span><font-awesome-icon class="text-lg" :icon="['fa', 'filter']" />Filtres</span>
        </span>

        <SideModal 
            v-model="openFilter" 
            title="Filtrer les biens"
            btnSuccessText="Rechercher"
            :btnSecondary="true"
            btnSecondaryText="Tout effacer"
            @ok="$emit('handleUpdateList')"
            @secondaryAction="$emit('resetFilter')"
        >
            <template v-slot:content>
                <div class="px-2">
                    <template v-if="!disableScisFilter && !disableBiensFilter">
                        <RadioButton class="my-3" title="Propriétaire" v-model="ownerTypeState" :options="ownerTypesOptions" name="owner"/>
                        <hr>
                    </template>
                    <AppInput 
                        v-model='keywordState' 
                        title='Mots-clés' 
                        id='mots-cles' 
                        placeholder="Recherche par libelle, ville ou cp"
                        class="my-3 w-64"
                    />
                    <hr>
                    <template v-if="!disableBiensFilter">
                        <SwitchForm v-model='switchImmeubleAppartement' title="Cacher les appartements d'immeubles" id='switchImmeubleAppartement' class="my-3" @edit="editDisplayImmeubleSwitch"/>
                        <hr>
                    </template>
                    <template v-if="!disableBiensFilter">
                        <MultiCheckbox v-model='typesState' :options="typesOptions" title="Types" id="types" class="my-3"/>
                        <hr>
                    </template>
                    <template v-if="!disableScisFilter && !disableBiensFilter">
                        <MultiCheckbox v-model='scisState' :options="scisOptions" title="SCI" id="scis" class="my-3"/>
                        <hr>
                    </template>
                    <SwitchForm v-model='archivedState' title='Archivé' id='archived' class="my-3"/>
                </div>
            </template>
        </SideModal>
    </div>
</template>

<script>

import { ref, inject, onMounted } from 'vue'
import { useVModel } from '../../composables/useVModel'
import AppInput from '../../form/AppInput';
import SwitchForm from '../../form/SwitchForm';
import MultiCheckbox from '../../form/MultiCheckbox';
import RadioButton from '../../form/RadioButton';
import SideModal from '../../composant/SideModal'

export default {
    components: {
        AppInput,
        SwitchForm,
        RadioButton,
        SideModal,
        MultiCheckbox
    },
    emits: [
        'update:keyword',
        'update:scis',
        'update:types',
        'update:archived',
        'update:ownerType',
        'update:displayImmeubleAppartement',
        'handleUpdateList',
        'resetFilter'
    ],
    props: {
        keyword: String, 
        scis: Array, 
        types: Array, 
        archived: Number,
        ownerType: String,
        displayImmeubleAppartement: String,
        disableScisFilter: {
            type: Boolean,
            default: false
        },
        disableBiensFilter: {
            type: Boolean,
            default: false
        }
    },

    setup(props) {
        const scisOptions = ref([]);
        const typesOptions = ref([
            {id: 'appartement', libelle: 'Appartement'},
            {id: 'maison', libelle: 'Maison'},
            {id: 'immeuble', libelle: 'Immeuble'},
        ]);
        const ownerTypesOptions = ref([
            {id: 'all', libelle: 'Tous'},
            {id: 'sci', libelle: 'SCI'},
            {id: 'perso', libelle: 'Nom propre'},
        ])

        const openFilter = ref(false)
        const displayImmeubleAppartementState = useVModel(props, 'displayImmeubleAppartement')
        const switchImmeubleAppartement = ref(displayImmeubleAppartementState === 'hide' ? 1 : 0)

        onMounted(async () => {
            const api = inject('api');
            const { results } = await api.sci.getScis(1, {archived: 0});
            
            scisOptions.value = results.map(s => {
                return {
                    id: s.uuid,
                    libelle: s.libelle
                }
            })
        });

        function editDisplayImmeubleSwitch() {
            displayImmeubleAppartementState.value = switchImmeubleAppartement.value ? 'hide' : 'all'
        }

        return {
            keywordState: useVModel(props, 'keyword'),
            scisState: useVModel(props, 'scis'),
            typesState: useVModel(props, 'types'),
            archivedState: useVModel(props, 'archived'),
            ownerTypeState: useVModel(props, 'ownerType'),
            displayImmeubleAppartementState,
            scisOptions,
            typesOptions,
            ownerTypesOptions,
            editDisplayImmeubleSwitch,
            openFilter,
            switchImmeubleAppartement
        }
    },
    watch: {
        displayImmeubleAppartementState(newValue) {
            this.switchImmeubleAppartement = newValue === 'hide' ? 1 : 0
        },
    },
};
</script>