<template>
    <div class="overflow-auto lg:overflow-visible ">     
        <table class=" w-full">
            <thead class="rounded-full">
                <tr class="border-t border-gray-200">
                    <th scope="col" class="text-left py-3.5 px-2 text-sm font-normal rtl:text-right text-gray-500 dark:text-gray-400">
                        Nom
                    </th>
                    <th scope="col" class="text-left py-3.5 px-2 text-sm font-normal rtl:text-right text-gray-500 dark:text-gray-400">
                        Date d'entrée
                    </th>
                    <th scope="col" class="text-left py-3.5 px-2 text-sm font-normal rtl:text-right text-gray-500 dark:text-gray-400">
                        Date de sortie
                    </th>
                    <th scope="col" class="text-left py-3.5 px-2 text-sm font-normal rtl:text-right text-gray-500 dark:text-gray-400">
                        Dépot de garantie
                    </th>
                    <th scope="col" class="relative">
                        <div v-show="!filters.archived">
                            <button class="btn btn-primary" @click="$refs.addHabitantModal.openModal()">
                                <font-awesome-icon class="text-md" :icon="['fa', 'plus']" />
                            </button>
                            <AddHabitantModal
                                ref="addHabitantModal"
                                @handleUpdateList="this.$emit('handleUpdateList')"
                            />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody 
                v-if="habitants && habitants.results && habitants.results.length !== 0"
            >
                <tr 
                    v-for="habitant of habitants.results" :key="habitant.id"
                    class="px-2 py-4 border-t border-gray-200"
                >
                    <td class="text-sm font-medium whitespace-nowrap">
                        <router-link :to="`/locataires/${habitant.locataire.uuid}`">
                            <div class="flex flex-wrap justify-start items-center rounded-md my-5">
                                <user-icon :user="habitant.locataire"/>
                                <div class="ml-3">
                                    <p class="text-sm font-normal text-gray-900 hover:text-indigo-500 hover:font-bold">
                                        {{habitant.locataire.prenom}} {{habitant.locataire.nom}} 
                                    </p>
                                    <p class="text-sm text-gray-500">{{habitant.locataire.email}}</p>
                                </div>
                            </div>
                        </router-link>
                    </td>
                    <td class="text-sm font-medium whitespace-nowrap">
                        <div
                            class="inline px-2 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 dark:bg-gray-800"
                            v-if="habitant.date_entree"
                            >
                            {{formatDate(habitant.date_entree)}}
                        </div>
                    </td>
                    <td class="text-sm font-medium whitespace-nowrap">
                        <div
                            class="inline px-2 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 dark:bg-gray-800"
                            v-if="habitant.date_sortie"
                        >
                            {{formatDate(habitant.date_sortie)}}
                        </div>
                    </td>
                    <td class="text-sm font-medium whitespace-nowrap">
                        <div
                            class="inline px-2 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 dark:bg-gray-800"
                            v-if="habitant.security_deposit"
                        >
                            {{habitant.security_deposit}} €
                        </div>
                    </td>
                    <td class="text-sm whitespace-nowrap">
                        <button-group> 
                            <template v-slot:content>
                                <a class="btn-dropdown-custom-gray" href="#" @click="edit(habitant)">Éditer</a>
                                <a v-if="habitant.archived" class="btn-dropdown-custom-info" href="#" @click="unarchive(habitant.uuid)">Désarchiver</a>
                                <a v-else class="btn-dropdown-custom-warning" href="#" @click="archive(habitant.uuid)">Archiver</a>
                                <a class="btn-dropdown-custom-danger" href="#" @click="remove(habitant.uuid)">Supprimer</a>
                            </template>
                        </button-group>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="!loaded">
                <tr
                    class="px-2 py-4 border-t border-gray-200"
                >
                    <td colspan="5">
                        <SpinnerLoader class="mt-5"></SpinnerLoader>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr
                    class="px-2 py-4 border-t border-gray-200"
                >
                    <td colspan="5" class="text-center">
                        <p class="text-sm text-indigo-400 my-4">Aucun habitant n'a été trouvé.</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <PaginationBlock v-if="habitants && habitants.total > 0" :paginator="habitants" @changePage="changePage"/>
        <PrimaryModal
            title="Archiver l'habitant"
            ref="archiveHabitantModal"
            btnSuccessText="Archiver"
            classButton="btn-warning"
            @ok="archiveHabitant()"
        >
            <template v-slot:content>
                <p class="text-sm">Êtes-vous sûr de vouloir archiver cet habitant</p>
            </template>
        </PrimaryModal>
        <PrimaryModal
            title="Désarchiver l'habitant"
            ref="unarchiveHabitantModal"
            btnSuccessText="Désarchiver"
            classButton="btn-info"
            @ok="unarchiveHabitant()"
        >
            <template v-slot:content>
                <p class="text-sm">Êtes-vous sûr de vouloir désarchiver cet habitant</p>
            </template>
        </PrimaryModal>
        <PrimaryModal
            title="Supprimer l'habitant"
            ref="removeHabitantModal"
            btnSuccessText="Supprimer"
            classButton="btn-danger"
            @ok="removeHabitant()"
        >
            <template v-slot:content>
                <p class="text-sm">
                    <span class="font-bold text-md text-red-600">
                        <font-awesome-icon :icon="['fa', 'exclamation-circle']" /> Attention
                    </span> 
                    Vous êtes sur le point de supprimer cet habitant. Cette action est irréversible, en êtes-vous sûr ?
                </p>
            </template>
        </PrimaryModal>
        <EditHabitantModal
            :habitantData="currentHabitant"
            ref="editHabitantModal"
            @handleUpdateList="this.$emit('handleUpdateList')"
        />
    </div>
</template>

<script>

import SpinnerLoader from './../../../loader/SpinnerLoader';
import PaginationBlock from './../../../composant/PaginationBlock';
import ButtonGroup from './../../../form/ButtonGroup';
import PrimaryModal from './../../../composant/PrimaryModal';
import AddHabitantModal from './../../../modal/habitant/AddHabitantModal.vue';
import EditHabitantModal from './../../../modal/habitant/EditHabitantModal.vue';

export default {
    components: {
        SpinnerLoader,
        PaginationBlock,
        ButtonGroup,
        PrimaryModal,
        AddHabitantModal,
        EditHabitantModal
    },
    props: {
        habitants: {
            type: Object,
            default() {
                return {}
            }
        },
        loaded: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            habitantUuid: null,
            currentHabitant: null
        }
    },

    methods: {
        changePage(page) {
            this.$emit('change-page', page);
        },

        archive(habitantUuid) {
            this.habitantUuid = habitantUuid
            this.$refs.archiveHabitantModal.openModal()
        },

        unarchive(habitantUuid) {
            this.habitantUuid = habitantUuid
            this.$refs.unarchiveHabitantModal.openModal()
        },

        remove(habitantUuid) {
            this.habitantUuid = habitantUuid
            this.$refs.removeHabitantModal.openModal()
        },

        async archiveHabitant() {
            const bienUuid = this.$route.params.bienUuid

            if (!this.habitantUuid || !bienUuid) {
                return;
            }

            this.$refs.archiveHabitantModal.activateLoader();

            await this.$api.habitant.archiveHabitant(bienUuid, this.habitantUuid)
            .then(() => {
                this.$toast({title: 'Succès', description: "L'habitant à été archivé avec succès."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.archiveHabitantModal.deactivateLoader();
            this.$refs.archiveHabitantModal.dismissModal()
            this.$emit('handleUpdateList')
        },

        async unarchiveHabitant() {
            const bienUuid = this.$route.params.bienUuid

            if (!this.habitantUuid || !bienUuid) {
                return;
            }

            this.$refs.unarchiveHabitantModal.activateLoader();

            await this.$api.habitant.unarchiveHabitant(bienUuid, this.habitantUuid)
            .then(() => {
                this.$toast({title: 'Succès', description: "L'habitant à été désarchivé avec succès."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.unarchiveHabitantModal.deactivateLoader();
            this.$refs.unarchiveHabitantModal.dismissModal()
            this.$emit('handleUpdateList')
        },

        async removeHabitant() {
            const bienUuid = this.$route.params.bienUuid

            if (!this.habitantUuid || !bienUuid) {
                return;
            }

            this.$refs.removeHabitantModal.activateLoader();

            await this.$api.habitant.removeHabitant(bienUuid, this.habitantUuid)
            .then(() => {
                this.$toast({title: 'Succès', description: "L'habitant à été supprimé avec succès."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.removeHabitantModal.deactivateLoader();
            this.$refs.removeHabitantModal.dismissModal()
            this.$emit('handleUpdateList')
        },

        async edit(habitant) {
            this.currentHabitant = {
                uuid: habitant.uuid,
                date_entree: habitant.date_entree ? new Date(habitant.date_entree) : null,
                date_sortie: habitant.date_sortie ? new Date(habitant.date_sortie) : null,
                security_deposit: habitant.security_deposit
            }
            this.$refs.editHabitantModal.openModal()
        }
    }
};
</script>