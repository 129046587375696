<template>
    <div>
        <div v-if="sci">
            <div v-if="!sci.archived" class="mt-4 bg-yellow-100 border-l-4 p-5 border-yellow-400 font-medium">
                <h4 class="text-gray-800">Archiver cette SCI</h4>
                <p class="text-sm text-gray-600">Cette action retirera la SCI de votre liste, vous pourrez toujours y accéder pour consulter les informations.</p>
                <button class="btn-warning mt-2" @click.prevent="$refs.archiveSciModal.openModal();">
                    Archiver
                </button>
                <PrimaryModal
                    title="Archiver la SCI"
                    ref="archiveSciModal"
                    btnSuccessText="Archiver"
                    classButton="btn-warning"
                    @ok="archiveSci()"
                >
                    <template v-slot:content>
                        <p class="text-sm">Êtes-vous sûr de vouloir archiver cette SCI. Cette action peut être réversible dans la section paramètre.</p>
                    </template>
                </PrimaryModal>
            </div>
            <div v-else class="mt-4 bg-blue-100 border-l-4 p-5 border-blue-400 font-medium">
                <h4 class="text-gray-800">Désarchiver cette SCI</h4>
                <p class="text-sm text-gray-600">Cette action retirera la SCI des archives.</p>
                <button class="btn-info mt-2" @click.prevent="$refs.unarchiveSciModal.openModal();">
                    Désarchiver
                </button>
                <PrimaryModal
                    title="Désarchiver la SCI"
                    ref="unarchiveSciModal"
                    btnSuccessText="Désarchiver"
                    classButton="btn-info"
                    @ok="unarchiveSci()"
                >
                    <template v-slot:content>
                        <p class="text-sm">Désarchiver cette SCI.</p>
                    </template>
                </PrimaryModal>
            </div>
        </div>
        <SpinnerLoader v-else class="mt-5"></SpinnerLoader>
        <div class="mt-4 bg-red-100 border-l-4 p-5 border-red-400 font-medium">
            <h4 class="text-gray-800">Supprimer cette SCI</h4>
            <p class="text-sm text-gray-400">Cette action supprimera complètement la SCI. Elle est irréversible.</p>
            <button class="btn-danger mt-2" @click.prevent="$refs.removeSciModal.openModal();">
                Supprimer
            </button>
            <PrimaryModal
                title="Supprimer la SCI"
                ref="removeSciModal"
                btnSuccessText="Supprimer"
                classButton="btn-danger"
                @ok="removeSci()"
            >
                <template v-slot:content>
                    <p class="text-sm">
                        <span class="font-bold text-md text-red-600"><font-awesome-icon :icon="['fa', 'exclamation-circle']" /> Attention</span> 
                        Vous êtes sur le point de supprimer cette SCI. Cette action est irréversible, en êtes-vous sûr ?</p>
                </template>
            </PrimaryModal>
        </div>
    </div>
</template>

<script>

import PrimaryModal from './../../components/composant/PrimaryModal';
import { inject } from 'vue'
import SpinnerLoader from './../../components/loader/SpinnerLoader';

export default {
    components: {
        PrimaryModal,
        SpinnerLoader
    },
    data() {
        return {
            sci: {},
            loaded: false,
        }
    },
    created() {
        this.loadSci()
    },
    methods: {
        async loadSci() {
            const api = inject('api');

            this.loaded = false
            this.sci = await api.sci.getSci(this.$route.params.sciUuid);
            this.loaded = true
        },
        async archiveSci() {
            if (this.sci.archived || !this.sci.uuid) {
                return;
            }

            this.$refs.archiveSciModal.activateLoader();

            await this.$api.sci.archiveSci(this.sci.uuid)
            .then(() => {
                this.$toast({title: 'Succès', description: "La SCI à été archivé avec succès."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.archiveSciModal.deactivateLoader();
            this.$router.push({ name: 'sci' })
        },
        async removeSci() {
            if (!this.$route.params.sciUuid) {
                return;
            }

            this.$refs.removeSciModal.activateLoader();

            await this.$api.sci.removeSci(this.$route.params.sciUuid)
            .then(() => {
                this.$toast({title: 'Succès', description: "La SCI à été supprimé avec succès."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.removeSciModal.deactivateLoader();
            this.$router.push({ name: 'sci' })
        },
        async unarchiveSci() {
            if (!this.sci.archived || !this.sci.uuid) {
                return;
            }

            this.$refs.unarchiveSciModal.activateLoader();

            await this.$api.sci.unarchiveSci(this.sci.uuid)
            .then(() => {
                this.$toast({title: 'Succès', description: "La SCI à été désarchivé avec succès."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.unarchiveSciModal.deactivateLoader();
            this.$router.push({ name: 'sci' })
        },
    },
};
</script>