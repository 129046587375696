import api from "./api";
import { catchValidationErrors } from "./catchErrors";

class BienService {
    async getBiens(page, form = {}) {
        const { data } = await api.get(`/api/biens?page=${page}`, { params: form }).catch(catchValidationErrors);

        return data;
    }

    async getBien(bien_uuid) {
        const { data } = await api.get(`/api/biens/${bien_uuid}`).catch(catchValidationErrors);

        return data;
    }

    async addBien(form) {
        const { data } = await api.post(`/api/biens`, {...form}).catch(catchValidationErrors);

        return data;
    }

    async updateBien(bien_uuid, form) {
        return await api.put(`/api/biens/${bien_uuid}`, {...form}).catch(catchValidationErrors);
    }

    async archiveBien(bien_uuid) {
        return await api.patch(`/api/biens/${bien_uuid}/archived`).catch(catchValidationErrors);
    }

    async unarchiveBien(bien_uuid) {
        return await api.patch(`/api/biens/${bien_uuid}/unarchived`).catch(catchValidationErrors);
    }

    async removeBien(bien_uuid) {
        return await api.delete(`/api/biens/${bien_uuid}`).catch(catchValidationErrors);
    }

    async getTaxeFoncieres(page, bien_uuid) {
        const { data } = await api.get(`/api/biens/${bien_uuid}/taxe-foncieres?page=${page}&limit=3`).catch(catchValidationErrors);

        return data;
    }

    async addTaxeFonciere(bien_uuid, form) {
        const { data } = await api.post(`/api/biens/${bien_uuid}/taxe-foncieres`, form).catch(catchValidationErrors);

        return data;
    }

    async updateTaxeFonciere(bien_uuid, taxe_fonciere_uuid, form) {
        return await api.put(`/api/biens/${bien_uuid}/taxe-foncieres/${taxe_fonciere_uuid}`, {...form}).catch(catchValidationErrors);
    }

    async removeTaxeFonciere(bien_uuid, taxe_fonciere_uuid) {
        return await api.delete(`/api/biens/${bien_uuid}/taxe-foncieres/${taxe_fonciere_uuid}`).catch(catchValidationErrors);
    }

    async getExpenses(page, bien_uuid) {
        const { data } = await api.get(`/api/biens/${bien_uuid}/expenses?page=${page}&limit=10`).catch(catchValidationErrors);

        return data;
    }

    async addExpense(bien_uuid, form) {
        const { data } = await api.post(`/api/biens/${bien_uuid}/expenses`, form).catch(catchValidationErrors);

        return data;
    }

    async updateExpense(bien_uuid, expense_uuid, form) {
        return await api.put(`/api/biens/${bien_uuid}/expenses/${expense_uuid}`, {...form}).catch(catchValidationErrors);
    }

    async removeExpense(bien_uuid, expense_uuid) {
        return await api.delete(`/api/biens/${bien_uuid}/expenses/${expense_uuid}`).catch(catchValidationErrors);
    }

    async getLoyers(page, bien_uuid) {
        const { data } = await api.get(`/api/biens/${bien_uuid}/loyers?page=${page}&limit=10`).catch(catchValidationErrors);

        return data;
    }

    async addLoyer(bien_uuid, form) {
        const { data } = await api.post(`/api/biens/${bien_uuid}/loyers`, form).catch(catchValidationErrors);

        return data;
    }

    async updateLoyer(bien_uuid, loyer_uuid, form) {
        return await api.put(`/api/biens/${bien_uuid}/loyers/${loyer_uuid}`, {...form}).catch(catchValidationErrors);
    }

    async removeLoyer(bien_uuid, loyer_uuid) {
        return await api.delete(`/api/biens/${bien_uuid}/loyers/${loyer_uuid}`).catch(catchValidationErrors);
    }

    async getNextLoyer(bien_uuid) {
        const { data } = await api.get(`/api/biens/${bien_uuid}/loyers/next`).catch(catchValidationErrors);

        return data;
    }

    async updateAutoLoyer(bien_uuid, form) {
        return await api.patch(`/api/biens/${bien_uuid}/auto-loyer`, {...form}).catch(catchValidationErrors);
    }

    async validateLoyer(bien_uuid, loyer_uuid) {
        return await api.patch(`/api/biens/${bien_uuid}/loyers/${loyer_uuid}/validate`).catch(catchValidationErrors);
    }
}

export default new BienService();
