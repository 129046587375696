
export default {
    getMonthOptions: (state) => {
      return state.monthOptions;
    },

    getMonth: (state) => (month) => {
      return state.monthOptions.find(m => m.code == month)
    },

    getDayOptions: (state) => {
      return state.dayOptions;
    }
}