<template>
    <input
        @input="(event) => $emit('update:checked', event.target.checked)"
         type="checkbox"
        :checked="checked"
        :id="fieldId"
        class="mr-2 hidden"
    />
    <label
        :for="fieldId"
        class="flex flex-row items-center font-bold text-gray-800 cursor-pointer select-none"
    >
        <font-awesome-icon :class="checked ? 'text-indigo-600' : 'text-gray-900'" :icon="[checked ? 'fa' : 'far', checked ? 'check-square' : 'square']" />
        <span class="ml-2 font-normal">{{ libelle }}</span>
    </label>
</template>
  
<script>

export default {
    props: {
        libelle: String,
        fieldId: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
        },
    },
};
</script>
  