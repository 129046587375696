export function catchValidationErrors(err) {
    console.log(err)
    if (err.response.status === 400 && err.response.data.errors) {
        let errors = {};

        for (const error of err.response.data.errors) {
            errors[error.propertyPath] = error.message;
        }

        err.response.data.errors = errors;

        return Promise.reject(err.response.data);
    }

    return Promise.reject(err);
}

export function catchLoginError(err) {
    if (
        err && err.response &&
        err.response.status === 401 &&
        err.response.data.type && err.response.data.type === "access_blocked"
    ) {
        return Promise.reject("Votre compte est bloqué. Veuillez contacter un administrateur.");
    }

    if (err && err.response && err.response.data &&
        err.response.data.code === 401
    ) {
        return Promise.reject("Email ou mot de passe invalide.");
    }

    return Promise.reject(err);
}