import { createStore } from 'vuex'
import { auth } from './auth.module';
import state from './state'
import getters from './getters'


export default createStore({
  state,
  mutations: {},
  actions: {},
  getters,
  modules: {
    auth,
  }
})
