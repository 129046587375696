import api from "./api";
import TokenService from "./token.service";
import jwt_decode from "jwt-decode";
import { catchLoginError } from "./catchErrors";

class AuthService {
    async login({ username, password }) {
        const { data } = await api
            .post("/api/login_check", {
                username,
                password
            })
            .catch(catchLoginError);

        if (!data.token) {
            return Promise.reject();
        }

        const userData = await jwt_decode(data.token)

        const user = {
            refreshToken: data.refresh_token,
            token: data.token,
            uuid: userData.uuid,
            name: userData.name,
            role: userData.roles,
            color: userData.color,
            initials: userData.initials,
            settings: userData.settings
        }

        await TokenService.setUser(user);

        return user;
    }

    logout() {
        TokenService.removeUser();
    }

    resetPasswordRequest(email) {
        return api.post('/api/recovery/reset', {
            email
        })
    }

    checkResetPasswordToken(token) {
        return api.get(`/api/recovery/${token}`);
    }

    resetPassword(token, password) {
        return api.post(`/api/recovery/${token}/change`, {
            password
        })
    }
}

export default new AuthService();