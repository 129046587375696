<template>
    <div class="w-full flex items-center justify-center">
        <div
            class="animate-spin ease-linear rounded-full border-4 border-top- border-t-4 border-gray-200 h-8 w-8 m-6 text-indigo-600"
            style="border-top-color: rgba(79, 70, 229, var(--tw-bg-opacity));"
        ></div>
    </div>
</template>
<script>

export default {

};
</script>
