<template>
<div>
    <label class="form-label" :for="id">
        {{title}}
    </label>

    <div class="flex flex-col items-start justify-center">
        <CheckboxForm
            v-for="option in options"
            :checked="modelValue.includes(option.id)"
            @update:checked="check(option.id, $event)"
            :fieldId="option.libelle"
            :libelle="option.libelle"
            :key="option"
        />
    </div>
</div>
</template>

<script>
import CheckboxForm from './CheckboxForm';

export default {
    components: {
        CheckboxForm,
    },
    props: {
        modelValue: [Array],
        options: {
            type: Array,
            required: true,
        },
        title: String,
        id: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const check = (optionId, checked) => {
            let updatedValue = [...props.modelValue];
            
            if (checked) {
                updatedValue.push(optionId);
            } else {
                updatedValue.splice(updatedValue.indexOf(optionId), 1);
            }
            
            context.emit("update:modelValue", updatedValue);
        };

        return {
            check,
        };
    },
};
</script>