<template>
    <PrimaryModal
        title="Ajouter un habitant"
        ref="addHabitantModal"
        btnSuccessText="Ajouter"
        :disabledBtn="checkFieldValid"
        @ok="addHabitant()"
        :width="750"
    >
        <template v-slot:content>
            <div class="md:col-span-3">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <AppInput 
                        v-model='habitant.prenom' 
                        class="mt-3 mr-2" 
                        title='Prénom' 
                        id='firstname' 
                    />
                    <AppInput 
                        v-model='habitant.nom' 
                        class="mt-3" 
                        title='Nom' 
                        id='lastname' 
                    />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <AppInput 
                        v-model='habitant.email' 
                        class="mt-3 mr-2" 
                        title='Email' 
                        id='email' 
                        :validations="['required', 'email']"
                        @hasError="handleFormError"
                        :check-on-create="false"
                    />
                    <AppInput 
                        v-model='habitant.telephone' 
                        class="mt-3" 
                        title='Téléphone' 
                        id='phone' 
                    />
                </div>

                <div class="grid grid-cols-1 md:grid-cols-3">
                    <DatePicker 
                        v-model='habitant.dateEntree' 
                        class="mt-3 mr-2" 
                        title="Date d'entrée" 
                        id='dateEntree' 
                    />
                    <DatePicker 
                        v-model='habitant.dateSortie' 
                        class="mt-3 mr-2" 
                        title="Date de sortie" 
                        id='dateSortie' 
                    />
                    <AppInput 
                        v-model='habitant.securityDeposit'
                        :validations="['number']"
                        class="mt-3"
                        title='Dépot de garantie'
                        id='security_deposit'
                        icon="euro-sign"
                    />
                </div>
            </div>
        </template>
    </PrimaryModal>
</template>

<script>

import AppInput from './../../form/AppInput';
import DatePicker from './../../form/DatePicker';
import PrimaryModal from './../../composant/PrimaryModal';

export default {
    components: {
        PrimaryModal,
        AppInput,
        DatePicker
    },
    data() {
        return {
            habitant: {
                prenom: "",
                nom: "",
                telephone: "",
                email: "",
                dateEntree: null,
                dateSortie: null,
                securityDeposit: null,
            },
            formInvalid: []
        }
    },
    computed: {
        checkFieldValid() {
            return this.formInvalid.length !== 0 || !this.$validator.validateEmail(this.habitant.email);
        }
    },
    methods: {
        openModal() {
            this.$refs.addHabitantModal.openModal();
        },
        async addHabitant() {
            const bienUuid = this.$route.params.bienUuid

            if (this.checkFieldValid || !bienUuid) {
                return;
            }

            this.$refs.addHabitantModal.activateLoader();

            await this.$api.habitant.addHabitant(bienUuid, {
                locataire: {
                    prenom: this.habitant.prenom,
                    nom: this.habitant.nom,
                    telephone: this.habitant.telephone,
                    email: this.habitant.email,
                },
                date_entree: this.habitant.dateEntree ? this.habitant.dateEntree.toISOString() : undefined,
                date_sortie: this.habitant.dateSortie ? this.habitant.dateSortie.toISOString() : undefined,
                security_deposit: this.habitant.securityDeposit ? parseFloat(this.habitant.securityDeposit) : undefined
            })
            .then((result) => {
                this.$toast({title: 'Succès', description: "L'habitant à été créée avec succès."}, this.$toastSuccess);

                return Promise.resolve(result)
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.addHabitantModal.deactivateLoader();
            this.$refs.addHabitantModal.dismissModal();
            this.$emit('handleUpdateList');
        },
        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },
    }
};
</script>