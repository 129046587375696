<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    computed: {
        user () {
            return "";
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch('auth/logout');
        }
    },
};
</script>