<template>
    <div>
        <input
            :id="fieldId"
            @click="(event) => $emit('update:checked', event.target.checked)"
            type="radio"
            :checked="checked"
            class="appearance-none flex"
            :name="name"
        />
        <label
            :for="fieldId"
            class="cursor-pointer mb-1 py-2 px-8 flex items-center justify-center truncate select-none text-sm rounded-md"
            :class="getClass"
        >
            {{ libelle }}
        </label>
    </div>
</template>
  
<script>

export default {
    props: {
        libelle: String,
        name: {
            type: String,
            required: true,
        },
        fieldId: String,
        checked: {
            type: Boolean,
        },
    },
    computed: {
        getClass() {
            if (this.checked) {
                return "border border-indigo-600 bg-indigo-600 text-white"
            }

            return "border border-indigo-600 text-gray-900 hover:bg-indigo-600 hover:text-white"
        }
    }
};
</script>
  