<template>
        <span
            class="flex items-center justify-center relative z-10 h-12 w-12 overflow-hidden rounded-full shadow focus:outline-none"
            :class="color"
        >
            {{ user.initials }}
        </span>
</template>

<script>

export default {
    props: {
        user: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    computed: {
        color() {
            if (this.user && this.user.color) {
                return `bg-${this.user.color} text-white`;
            }

            return '';
        },
    }
};
</script>
