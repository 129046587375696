<template>
    <input
        :id="fieldId"
        @input="(event) => $emit('update:checked', event.target.checked)"
        type="radio"
        :checked="checked"
        class="appearance-none"
        :name="name"
    />
    <label
        :for="fieldId"
        class="cursor-pointer mx-1 px-2 flex items-center justify-center truncate select-none rounded-full text-sm py-2"
        :class="getClass"
    >
        {{ libelle }}
    </label>
</template>
  
<script>

export default {
    props: {
        libelle: String,
        name: {
            type: String,
            required: true,
        },
        fieldId: String,
        checked: {
            type: Boolean,
        },
    },
    computed: {
        getClass() {
            if (this.checked) {
                return "border border-indigo-600 bg-indigo-600 text-white"
            }

            return "border border-indigo-600 text-gray-900 hover:bg-indigo-600 hover:text-white"
        }
    }
};
</script>
  