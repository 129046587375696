<template>
    <div>
        <label class="form-label" v-if="!hideLabel">
            {{title}}
        </label>
        <div class="flex w-full relative" v-if="!displayBlock">
            <RadioForm
                v-for="option in options"
                :checked="modelValue === option.id"
                @update:checked="check(option.id, $event)"
                :name="name"
                :fieldId="option.id"
                :libelle="option.libelle"
                :key="option"
            />
        </div>
        <div class="flex flex-col" v-else>
            <RadioBlockForm
                v-for="option in options"
                :checked="modelValue === option.id"
                @update:checked="check(option.id, $event)"
                :name="name"
                :fieldId="option.id"
                :libelle="option.libelle"
                :key="option"
            />
        </div>
    </div>
</template>
    
<script>    
import RadioForm from './RadioForm';
import RadioBlockForm from './RadioBlockForm';

export default {
    components: {
        RadioForm,
        RadioBlockForm,
    },

    props: {
        modelValue: [Number, String],
        title: String,
        options: Array,
        name: String,
        hideLabel: {
            type: Boolean,
            default: false
        },
        displayBlock: {
            type: Boolean,
            default: false
        }
    },

    setup(props, context) {
        const check = (optionId) => {
            context.emit("update:modelValue", optionId);
        };

        return {
            check,
        };
    },
};
</script>