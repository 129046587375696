<template>
    <router-link :to="`/locataires/${locataireUuid}`">
    <div class="flex items-center cursor-pointer">
        <span class="rounded-l-full">
            <span 
                v-if="habitant.locataire.initials" 
                class="rounded-full p-2 flex items-center justify-center w-8 h-8 text-white text-sm" 
                :class="disabled ? `bg-gray-400` : `bg-${habitant.locataire.color}`"
            >
            {{ habitant.locataire.initials }}
        </span>
        </span>
        <span class="px-1  flex-initial" :class="disabled ? 'text-gray-400' : 'text-indigo-700'">
            {{ habitant.locataire.prenom }} {{ habitant.locataire.nom }}
        </span>
    </div>
    </router-link>
</template>

<script>
export default {
    props: {
        habitant: {
            type: Object,
            default() {
                return {}
            }
        },
        locataireUuid: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
};
</script>