import api from "./api";
import { catchValidationErrors } from "./catchErrors";

class HabitantService {
    async getHabitants(page, bienUuid, form = {}) {
        const { data } = await api.get(`/api/biens/${bienUuid}/habitants?page=${page}`, { params: form }).catch(catchValidationErrors);

        return data;
    }

    async addHabitant(bienUuid, form) {
        const { data } = await api.post(`/api/biens/${bienUuid}/habitants`, {...form}).catch(catchValidationErrors);

        return data;
    }

    async editHabitant(bienUuid, habitantUuid, form) {
        const { data } = await api.put(`/api/biens/${bienUuid}/habitants/${habitantUuid}`, {...form}).catch(catchValidationErrors);

        return data;
    }

    async archiveHabitant(bienUuid, habitantUuid) {
        return await api.patch(`/api/biens/${bienUuid}/habitants/${habitantUuid}/archived`).catch(catchValidationErrors);
    }

    async unarchiveHabitant(bienUuid, habitantUuid) {
        return await api.patch(`/api/biens/${bienUuid}/habitants/${habitantUuid}/unarchived`).catch(catchValidationErrors);
    }

    async removeHabitant(bienUuid, habitantUuid) {
        return await api.delete(`/api/biens/${bienUuid}/habitants/${habitantUuid}`).catch(catchValidationErrors);
    }
}

export default new HabitantService();
