class FormService {
    getListBienForm() {
        return {
            mergeBien: 'all',
            keyword: '',
            scis: [],
            types: [],
            archived: 0,
            ownerType: 'all',
            displayImmeubleAppartement: 'hide',
            immeubleUuid: null
        }
    }

    getListSciForm() {
        return {
            libelle: '',
            archived: 0,
        }
    }

    getListLocataireForm() {
        return {
            keyword: '',
            archived: 0,
        }
    }

    getForm(key) {
        const form = JSON.parse(localStorage.getItem(key));

        if (form) {
            return form
        }

        switch (key) {
            case 'list_bien_form':
               return this.getListBienForm();
            case 'list_sci_form':
                return this.getListSciForm();
            case 'list_locataire_form':
                return this.getListLocataireForm();
            default:
               return {};
        }
    }

    updateForm(key, object) {
        let form = JSON.parse(localStorage.getItem(key));

        form = {
            ...form,
            ...object
        }
        localStorage.setItem(key, JSON.stringify(form));
    }

    resetForm(key) {
        localStorage.removeItem(key);
    }
}
  
export default new FormService();