<template>
    <PrimaryModal
        :title="loyer.uuid ? 'Éditer un loyer' : 'Ajouter un loyer'"
        ref="editLoyerModal"
        :btnSuccessText="loyer.uuid ? 'Éditer' : 'Ajouter' "
        :disabledBtn="!checkFieldValid"
        @ok="loyer.uuid ? editLoyer() : addLoyer()"
        :width="750"
    >
        <template v-if="loaded" v-slot:content>
            <template v-if="bien">
                <div v-if="bien.auto_loyer" class="mt-4 bg-yellow-100 border-l-4 p-5 border-yellow-400 font-medium">
                    <h4 class="text-gray-800">Loyer automatique</h4>
                    <p class="text-sm text-gray-600">Attention, il existe un loyer automatique pour ce bien.</p>
                    <p v-if="bien.auto_loyer_at" class="text-sm text-indigo-600">Il se déclenche tous les {{ bien.auto_loyer_at }} de chaque mois.</p>
                </div>
                <div v-else class="mt-4 bg-blue-100 border-l-4 p-5 border-blue-400 font-medium">
                    <h4 class="text-gray-800">Loyer automatique</h4>
                    <router-link :to="`/bien/${bien.uuid}/settings`">
                        <p class="text-sm text-gray-600 hover:text-indigo-600 cursor-pointer">Configurer un loyer automatique</p>
                    </router-link>
                </div>
            </template>

            <div class="md:col-span-3">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div
                        class="mt-3 mr-2" 
                    >
                        <label class="form-label" for="month">
                            Mois*
                        </label>
                        <div
                            class="relative"
                        >
                            <Multiselect 
                                v-model='loyer.month'
                                id='month'
                                :options="monthOptions"
                                label="label"
                                track-by="label"
                                v-bind="$multiselectBind"
                            />
                        </div>
                    </div>

                    <DatePicker 
                        v-model="loyer.year"
                        class="mt-3 mr-2" 
                        title="Année" 
                        :validations="['required']"
                        id='year'
                        minimum-type="year"
                        maximum-type="year"
                        @changeYear="(event) => loyer.year = event.toString()"
                    />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3">
                    <AppInput 
                        v-model='loyer.loyer' 
                        class="mt-3 mr-2" 
                        title='Loyer'
                        id='loyer'
                        :validations="['number']"
                        @hasError="handleFormError"
                        :check-on-create="false"
                        icon="euro-sign"
                    />
                    <AppInput 
                        v-model='loyer.charges' 
                        class="mt-3 mr-2" 
                        title='Charges' 
                        id='charges' 
                        :validations="['number']"
                        @hasError="handleFormError"
                        :check-on-create="false"
                        icon="euro-sign"
                    />
                    <DatePicker 
                        v-model='loyer.paiement_date' 
                        class="mt-3 mr-2" 
                        title="Date de paiement" 
                        id='paiementDate' 
                    />
                </div>
                <div class="grid grid-cols-1">
                    <div
                        class="mt-3 mr-2" 
                    >
                        <label class="form-label" for="month">
                            Habitant(s)*
                        </label>
                        <SelectHabitantSwitch v-model="loyer.habitants" @update="(event) => loyer.habitants = event"/>
                    </div>
                </div>
            </div>
        </template>
        <template v-else v-slot:content>
            <SpinnerLoader class="mt-5"></SpinnerLoader>
        </template>
    </PrimaryModal>
</template>

<script>

import AppInput from './../../../../form/AppInput';
import DatePicker from './../../../../form/DatePicker';
import SelectHabitantSwitch from './../../../../form/habitant/SelectHabitantSwitch.vue';
import SpinnerLoader from './../../../../loader/SpinnerLoader';
import Multiselect from 'vue-multiselect'
import PrimaryModal from './../../../../composant/PrimaryModal';
import { mapGetters } from "vuex";

export default {
    components: {
        PrimaryModal,
        AppInput,
        DatePicker,
        Multiselect,
        SpinnerLoader,
        SelectHabitantSwitch
    },
    data() {
        return {
            loyer: {
                uuid: null,
                year: "",
                month: null,
                loyer: "",
                charges: "",
                datePaiement: null,
                habitants: [],
            },
            formInvalid: [],
            loaded: false
        }
    },

    props: {
        bien: {
            type: Object,
            default: null
        }
    },

    computed: {
        ...mapGetters({
            monthOptions: 'getMonthOptions',
            getMonth: 'getMonth'
        }),
        checkFieldValid() {
            return this.formInvalid.length === 0 && 
            this.loyer.year && this.loyer.month && 
            this.loyer.year.toString().length === 4;
        }
    },

    methods: {
        async openModal(loyer = null) {
            let currentHabitants = []

            if (loyer && loyer.uuid) {
                const {results} = await this.$api.habitant.getHabitants(1, this.$route.params.bienUuid, {archived: 0});
                currentHabitants = results
            }

            this.$refs.editLoyerModal.openModal();
            this.loyer = loyer ? {...loyer} : await this.$api.bien.getNextLoyer(this.$route.params.bienUuid);

            this.loyer.month = this.getMonth(this.loyer.month)
            this.loyer.paiement_date = this.loyer.paiement_date ? new Date(this.loyer.paiement_date) : null
            this.loyer.habitants = this.loyer.habitants.map(h => {
                return {
                    uuid: h.uuid,
                    selected: 1,
                    data: h
                }
            })

            for (const ch of currentHabitants) {
                const exist = this.loyer.habitants.filter(h => h.uuid === ch.uuid).length > 0

                if (!exist) {
                    this.loyer.habitants.push({
                        uuid: ch.uuid,
                        selected: 0,
                        data: ch
                    })
                }
            }

            this.loaded = true
        },
        async addLoyer() {
            const bienUuid = this.$route.params.bienUuid

            if (!this.checkFieldValid || !bienUuid) {
                return;
            }

            this.$refs.editLoyerModal.activateLoader();

            await this.$api.bien.addLoyer(bienUuid, {
                year: this.loyer.year.toString(),
                month: this.loyer.month.code,
                loyer: this.loyer.loyer ? parseFloat(this.loyer.loyer) : null,
                charges: this.loyer.charges ? parseFloat(this.loyer.charges): null,
                paiement_date: this.loyer.paiement_date ? this.loyer.paiement_date.toISOString() : undefined,
                habitants: this.loyer.habitants.filter(h => h.selected).map(h => {
                    return h.uuid
                })
            })
            .then((result) => {
                this.$toast({title: 'Succès', description: "Le loyer à été créée avec succès."}, this.$toastSuccess);

                return Promise.resolve(result)
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.editLoyerModal.deactivateLoader();
            this.$refs.editLoyerModal.dismissModal();
            this.$emit('handleUpdateList');
        },

        async editLoyer() {
            const bienUuid = this.$route.params.bienUuid

            if (!this.checkFieldValid || !bienUuid || !this.loyer.uuid) {
                return;
            }

            this.$refs.editLoyerModal.activateLoader();

            await this.$api.bien.updateLoyer(bienUuid, this.loyer.uuid, {
                year: this.loyer.year.toString(),
                month: this.loyer.month.code,
                loyer: this.loyer.loyer ? parseFloat(this.loyer.loyer) : null,
                charges: this.loyer.charges ? parseFloat(this.loyer.charges) : null,
                paiement_date: this.loyer.paiement_date ? this.loyer.paiement_date.toISOString() : undefined,
                habitants: this.loyer.habitants.filter(h => h.selected).map(h => {
                    return h.uuid
                })
            })
            .then((result) => {
                this.$toast({title: 'Succès', description: "Le loyer à été édité avec succès."}, this.$toastSuccess);

                return Promise.resolve(result)
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })

            this.$refs.editLoyerModal.deactivateLoader();
            this.$refs.editLoyerModal.dismissModal();
            this.$emit('handleUpdateList');
        },

        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },
    }
};
</script>