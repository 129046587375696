<template>
<div>
    <label class="form-label" :for="id" v-if="title">
        {{title}}{{ validations.includes('required') ? '*' : ''}}
    </label>
    <div
        v-if="!disabled"
        class="relative"
    >
        <input
            :type="type"
            :id="id"
            :value="modelValue"
            @input="inputChange"
            @focusout="focusout"
            :required="validations.includes('required')"
            :class="hasError ? 'form-control-error' : 'form-control'"
            autocomplete="off"
            @keyup.enter="$emit('onEnter')"
            :placeholder="placeholder"
        >
        <span v-if="icon" class="absolute inset-y-0 right-0 flex items-center px-2">
            <font-awesome-icon class="text-md text-gray-500 z-10" :icon="['fa', icon]" />
        </span>
    </div>
    <div v-else class="form-control bg-gray-200">{{ modelValue }}</div>
    <p v-if="hasError" class="text-red-500 text-xs italic">{{ displayError }}</p>
</div>
</template>

<script>

import validator from '../../common/utils/Validator';

export default {
    data() {
        return {
            validationError: ''
        }
    },
    props: {
        modelValue: [Number, String],
        title: String,
        id: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: String,
        type: {
            type: String,
            default: 'text'
        },
        validations: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: null
        },
        checkOnCreate: {
            type: Boolean,
            default: true
        },
        checkOnUnfocus: {
            type: Boolean,
            default: false
        },
        icon: String
    },
    created() {
        if (this.checkOnCreate) {
            const event = {target: {value: this.modelValue}};
            this.checkValidations(event);
        }
    },
    computed: {
        hasError() {
            return !!(this.error || this.validationError);
        },
        displayError() {
            if (this.error) {
                return this.error;
            }

            if (this.validationError) {
                return this.validationError;
            }

            return '';
        }
    },
    methods: {
        inputChange(event) {
            this.$emit('update:modelValue', event.target.value);
            if (!this.checkOnUnfocus) {
                this.checkValidations(event);
            }
        },

        focusout(event) {
            if (this.checkOnUnfocus) {
                this.checkValidations(event);
            }
        },

        checkValidations(event) {
            const value = event.target.value;
            this.validationError = '';

            if (this.validations.includes('required') && value.length === 0) {
                this.validationError = validator.requiredMessage();
                return;
            }

            if (
                value &&
                this.validations.includes('integer') &&
                value.length !== 0 &&
                !validator.isOnlyNumber(value)
            ) {
                this.validationError = validator.onlyIntegerMessage();
                return;
            }

            if (
                value &&
                this.validations.includes('number') &&
                value.length !== 0 &&
                !validator.isNumber(value)
            ) {
                this.validationError = validator.onlyNumberMessage();
                return;
            }

            if (
                value &&
                this.validations.includes('year') &&
                value.length !== 0 &&
                !validator.isYear(value)
            ) {
                this.validationError = validator.onlyYearMessage();
                return;
            }
        }
    },
    watch: {
        hasError(newValue) {
            this.$emit('hasError', {name: this.id, value: newValue});
        }
    }
};
</script>