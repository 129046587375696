<template>
    <div>
        <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div class="flex flex-1 justify-between sm:hidden">
                <template v-if="paginator.pages > 1">
                    <button type="button" @click="changePage(1)" class="rounded-md" :class="displayChevronClass(1)">Première</button>
                    <div>
                        <button type="button" @click="changePage(this.paginator.page - 1)" class="rounded-md" :class="displayChevronClass(1)">Précédent</button>
                        <button type="button" @click="changePage(this.paginator.page + 1)" class="rounded-md" :class="displayChevronClass(paginator.pages)">Suivant</button>
                    </div>
                    <button type="button" @click="changePage(paginator.pages)" class="rounded-md" :class="displayChevronClass(paginator.pages)">Dernière</button>
                </template>
                <template v-else>
                    <p class="text-sm text-gray-700">{{paginator.total}} résultat<template v-if="paginator.total > 1">s</template></p>
                </template>
            </div>
            <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between" :class="reverse ? 'sm:flex-row-reverse' : ''">
                <template v-if="paginator.pages > 1">
                    <div>
                        <p class="text-sm text-gray-700">
                            Résultat
                            <span class="font-medium">{{((paginator.page - 1) * paginator.per_page) + 1}}</span>
                            <template v-if="(((paginator.page - 1) * paginator.per_page) + 1) !== (((paginator.page - 1) * paginator.per_page) + paginator.results.length)">
                                à
                                <span class="font-medium">{{((paginator.page - 1) * paginator.per_page) + paginator.results.length }}</span>
                            </template>
                            sur
                            <span class="font-medium">{{paginator.total}}</span>
                        </p>
                    </div>
                    <div>
                        <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                            <button @click="changePage(this.paginator.page - 1)" :class="displayChevronClass(1)" class="rounded-l-md px-2">
                                <span class="sr-only">Précédent</span>
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                                </svg>
                            </button>
                            <template v-if="paginator.pages < 10">
                                <template v-for="nav in paginator.pages" :key="nav">
                                    <button type="button" @click="changePage(nav)" :class="displayClass(nav)">{{nav}}</button>
                                </template>
                            </template>
                            <template v-else>
                                <button type="button" @click="changePage(1)" :class="displayClass(1)">1</button>
                                <template  v-if="paginator.page <= 2">
                                    <button type="button" @click="changePage(2)" :class="displayClass(2)">2</button>
                                    <button type="button" @click="changePage(3)" :class="displayClass(3)">3</button>
                                </template>
                                <button type="button" class="dot-pagination-button" v-if="paginator.page > 3">...</button>
                                <template v-if="paginator.page >=3 && paginator.page <= paginator.pages - 2">
                                    <button type="button" @click="changePage(paginator.page-1)" :class="displayClass(paginator.page-1)">{{paginator.page-1}}</button>
                                    <button type="button" @click="changePage(paginator.page)" :class="displayClass(paginator.page)">{{paginator.page}}</button>
                                    <button type="button" @click="changePage(paginator.page+1)" :class="displayClass(paginator.page+1)">{{paginator.page+1}}</button>
                                </template>
                                <button type="button" class="dot-pagination-button" v-if="paginator.page <= paginator.pages - 3">...</button>
                                <template  v-if="paginator.page >= paginator.pages-1">
                                    <button type="button" @click="changePage(paginator.pages-2)" :class="displayClass(paginator.pages-2)">{{paginator.pages-2}}</button>
                                    <button type="button" @click="changePage(paginator.pages-1)" :class="displayClass(paginator.pages-1)">{{paginator.pages-1}}</button>
                                </template>
                                <button type="button" @click="changePage(paginator.pages)" :class="displayClass(paginator.pages)">{{paginator.pages}}</button>
                            </template>
                            <button type="button" @click="changePage(this.paginator.page + 1)" :class="displayChevronClass(paginator.pages)" class="rounded-r-md px-2">
                                <span class="sr-only">Suivant</span>
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                </svg>
                            </button>
                        </nav>
                    </div>
                </template>
                <template v-else>
                    <p class="text-sm text-gray-700">{{paginator.total}} résultat<template v-if="paginator.total > 1">s</template></p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        paginator: {
            type: Object,
            default() {
                return {}
            }
        },
        reverse: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changePage(page) {
            if (page < 1 || page > this.paginator.pages) {
                return;
            }

            this.$emit('change-page', page);
        },

        displayClass(nav) {
            if (nav === this.paginator.page) {
                return 'active-pagination-button'
            }

            return 'pagination-button'
        },

        displayChevronClass(disabledPage) {
            if (disabledPage === this.paginator.page) {
                return 'disabled-chevron-pagination-button'
            }
            return 'chevron-pagination-button'
        }
    }
};
</script>
