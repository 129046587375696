<template>
  <div class="relative">
    <button 
        class="relative px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg dark:text-gray-300 hover:bg-gray-100"
        @click="open = !open"
        v-click-outside="onClickOutside"
    >
        <span class="px-1 py-1 inline-flex items-center text-sm text-gray-500">
            <span v-if="title" class="mr-2">{{ title }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
            </svg>
      </span>
    </button>

    <!--dropdown content: desktop-->
    <transition enter-active-class="transition-all duration-100 ease-out" leave-active-class="transition-all duration-350 ease-in" enter-class="opacity-0 scale-75" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-75">
      <div class="hidden md:block absolute shadow-lg border w-48 rounded px-2 py-2 text-sm mt-1 bg-white z-20" :class="placement === 'right' ? 'right-0' : 'left-0'" v-if="open">
        <slot name="content"></slot>
      </div>
    </transition>

    <!--dropdown content: mobile-->
    <transition enter-active-class="transition-all duration-100 ease-out" leave-active-class="transition-all duration-350 ease-in" enter-class="opacity-0 scale-75" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-75">
      <div class="md:hidden fixed inset-x-0 bottom-0 bg-white w-full px-2 py-2 shadow-2xl leading-loose z-20" v-if="open">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>
    
<script>

import vClickOutside from 'click-outside-vue3'
export default {
    data() {
        return {
            open: false,
        }
    },
    props: {
        placement: {
            type: String,
            default: "right",
            validator: (value) => ["right", "left"].indexOf(value) !== -1,
        },
        title: {
            type: String,
            default: ""
        }
    },
    methods: {
        onClickOutside () {
            this.open = false
        },
    },
    directives: {
        clickOutside: vClickOutside.directive
    }
};
</script>