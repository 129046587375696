<template>
    <div  v-if="loaded" class="grid grid-cols-1 md:grid-cols-5 mt-3">
        <div class="md:col-span-12 pr-5">
            <div class="flex justify-end">
                <LoaderButton title="Enregistrer" @ok="handleUpdateBien" :disabled="checkFieldValid" ref="btnValidate"></LoaderButton>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 mt-1">
                <div class="md:mr-4">
                    <AppInput 
                        v-model='bien.libelle' 
                        class="mt-3" 
                        title='Libelle' 
                        id='libelle' 
                        :validations="['required']"
                        @hasError="handleFormError"
                    />
                    <AppInput v-model='bien.adresse' class="mt-3" title='Adresse' id='adresse'/>
                    <div class="grid grid-cols-1 md:grid-cols-2">
                        <AppInput v-model='bien.ville' class="mt-3 md:pr-2" title='Ville' id='ville'/>
                        <AppInput v-model='bien.cp' class="mt-3" title='Code postal' id='cp'/>
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3">
                    <AppInput v-model='bien.loyer' class="mt-3 md:pr-2" title='Loyer' id='loyer' icon="euro-sign"/>
                    <AppInput v-model='bien.charges' class="mt-3 md:pr-2" title='Charges' id='charges' icon="euro-sign"/>
                    <div class="w-full mt-3 mr-2">
                        <label class="form-label" for="paiementDay">
                            Jour de paiement
                        </label>
                        <div
                            class="relative"
                        >
                            <Multiselect
                                v-model='bien.paiement_day'
                                id='paiementDay'
                                :options="paiementDayOptions"
                                v-bind="$multiselectBind"
                                placeholder=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <SpinnerLoader v-else class="mt-5"></SpinnerLoader>
</template>

<script>
import { inject } from 'vue'
import AppInput from './../../components/form/AppInput';
import SpinnerLoader from './../../components/loader/SpinnerLoader';
import LoaderButton from './../../components/form/LoaderButton';
import { mapGetters } from "vuex";
import Multiselect from 'vue-multiselect'

export default {
    components: {
        LoaderButton,
        SpinnerLoader,
        AppInput,
        Multiselect
    },
    data() {
        return {
            bien: {},
            loaded: false,
            formInvalid: [],
        }
    },
    computed: {
        checkFieldValid() {
            return this.formInvalid.length !== 0;
        },

        ...mapGetters({
            paiementDayOptions: 'getDayOptions',
        }),
    },
    created() {
        this.loadBien()
    },
    methods: {
        async loadBien() {
            const api = inject('api');

            this.loaded = false

            this.bien = await api.bien.getBien(this.$route.params.bienUuid);
            this.loaded = true
        },
        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },
        async handleUpdateBien() {
            if (this.checkFieldValid) {
                return;
            }

            this.$refs.btnValidate.activateLoader();

            const data = {
                libelle: this.bien.libelle,
                adresse: this.bien.adresse,
                cp: this.bien.cp,
                ville: this.bien.ville,
                paiement_day: this.bien.paiement_day && this.bien.paiement_day.length !== 0 ? this.bien.paiement_day : null,
            };

            if (this.bien.loyer) {
                data.loyer = parseFloat(this.bien.loyer)
            }

            if (this.bien.charges) {
                data.charges = parseFloat(this.bien.charges)
            }

            await this.$api.bien.updateBien(this.bien.uuid, data)
            .then(() => {
                this.$toast({title: 'Succès', description: "Le bien à bien été enregistré."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })
            
            this.$refs.btnValidate.deactivateLoader();
        }
    },
};
</script>