<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>

<script>
import EventBus from "./common/EventBus";

export default {
    computed: {
        layout() {
            return `${this.$route.meta.layout || 'default'}-layout`;
        }
    },
    mounted() {
        EventBus.on("logout", () => {
            this.router.push({ name: 'Logout'})
        });
    },
    beforeUnmount() {
        EventBus.remove("logout");
    }
};
</script>

<style lang="scss">

</style>
