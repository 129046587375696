import {createRouter, createWebHistory} from 'vue-router'
import LoginPage from '../views/auth/LoginPage.vue'
import LogoutPage from '../views/auth/LogoutPage.vue'
import PasswordResetPage from '../views/auth/PasswordResetPage.vue'
import HomePage from '../views/home/HomePage.vue'
import Error404Item from '../components/error/Error404Item.vue'
import Dashboard from '../views/DashboardPage.vue'
import ProfilPage from '../views/ProfilPage.vue'
import BienPage from '../views/BienPage.vue'
import BienDetailsPage from '../views/BienDetailsPage.vue'
import BienDetailsDetails from '../views/BienDetails/DetailsPage.vue'
import BienDetailsLocataire from '../views/BienDetails/LocatairesPage.vue'
import BienDetailsFinance from '../views/BienDetails/FinancesPage.vue'
import BienDetailsBiens from '../views/BienDetails/BiensPage.vue'
import BienDetailsSettings from '../views/BienDetails/SettingsPage.vue'
import SciPage from '../views/SciPage.vue'
import SciDetailsPage from '../views/SciDetailsPage.vue'
import SciDetailsDetails from '../views/SciDetails/DetailsPage.vue'
import SciDetailsBiens from '../views/SciDetails/BiensPage.vue'
import SciDetailsSettings from '../views/SciDetails/SettingsPage.vue'
import LocatairePage from '../views/LocatairePage.vue'
import LocataireDetailsPage from '../views/LocataireDetailsPage.vue'
import LocataireDetailsDetails from '../views/LocataireDetails/DetailsPage.vue'

const routes = [
    {
        path: '/logout',
        name: 'Logout',
        component: LogoutPage,
        meta: {
            title: 'Logout',
            layout: "empty"
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: {
            title: 'Login',
            layout: "empty"
        }
    },
    {
        path: '/password/reset',
        name: 'PasswordReset',
        component: PasswordResetPage,
        meta: {
            title: 'Reinitialisation du mot de passe',
            layout: "empty"
        }
    },
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                meta: {
                    title: "Dashboard",
                },
                component: Dashboard
            },
            {
                path: "page-not-found",
                name: "pageNotFound",
                meta: {
                    title: "Page introuvable",
                },
                component: Error404Item
            },
            {
                path: "profil",
                name: "profil",
                meta: {
                    title: "Mon profil",
                },
                component: ProfilPage
            },
            {
                path: "bien",
                name: "bien",
                meta: {
                    title: "Mes Biens",
                },
                component: BienPage,
            },
            {
                path: "bien/:bienUuid",
                name: "bienDetails",
                meta: {
                    title: "Détails"
                },
                component: BienDetailsPage,
                children: [
                    {
                        path: "details",
                        name: "bienDetailsDetails",
                        meta: {
                            title: "Détails du bien"
                        },
                        component: BienDetailsDetails,
                    },
                    {
                        path: "locataires",
                        name: "bienDetailsLocataires",
                        meta: {
                            title: "Locataire du bien"
                        },
                        component: BienDetailsLocataire,
                    },
                    {
                        path: "financials",
                        name: "bienDetailsFinancials",
                        meta: {
                            title: "Finance"
                        },
                        component: BienDetailsFinance,
                    },
                    {
                        path: "biens",
                        name: "bienDetailsBiens",
                        meta: {
                            title: "Bien de l'immeuble"
                        },
                        component: BienDetailsBiens,
                    },
                    {
                        path: "settings",
                        name: "bienDetailsSettings",
                        meta: {
                            title: "Paramètre du bien"
                        },
                        component: BienDetailsSettings,
                    },
                ]
            },
            {
                path: "sci",
                name: "sci",
                meta: {
                    title: "Mes SCI",
                },
                component: SciPage,
            },
            {
                path: "sci/:sciUuid",
                name: "sciDetails",
                meta: {
                    title: "Détails"
                },
                component: SciDetailsPage,
                children: [
                    {
                        path: "details",
                        name: "sciDetailsDetails",
                        meta: {
                            title: "Détails de la SCI"
                        },
                        component: SciDetailsDetails,
                    },
                    {
                        path: "biens",
                        name: "sciDetailsBiens",
                        meta: {
                            title: "Bien de la SCI"
                        },
                        component: SciDetailsBiens,
                    },
                    {
                        path: "settings",
                        name: "sciDetailsSettings",
                        meta: {
                            title: "Paramètre de la SCI"
                        },
                        component: SciDetailsSettings,
                    },
                ]
            },
            {
                path: "locataires",
                name: "locataire",
                meta: {
                    title: "Locataire",
                },
                component: LocatairePage,
            },
            {
                path: "locataires/:locataireUuid",
                name: "locataireDetails",
                meta: {
                    title: "Détails"
                },
                component: LocataireDetailsPage,
                children: [
                    {
                        path: "details",
                        name: "locataireDetailsDetails",
                        meta: {
                            title: "Détails du locataire"
                        },
                        component: LocataireDetailsDetails,
                    },
                ]
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/password/reset'];
    const authRequired = !publicPages.includes(to.path);

    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    if ((to.path === "/login" && loggedIn) || to.path === "/") {
        return next('/dashboard');
    }

    if (to.name === undefined) {
        return next('/page-not-found');
    }

    document.title = `${to.meta.title !== undefined ? `${to.meta.title} - ` : ''} Gesti`;

    next();
})

export default router
