import { createApp } from 'vue'
import App from './App.vue'
import DefaultLayout from './components/layout/DefaultLayout.vue';
import EmptyLayout from './components/layout/EmptyLayout.vue';
import UserIcon from './components/composant/utilisateur/UserIcon.vue';
import router from './router'
import store from './store'
import './assets/main.scss'
import api from "./services";
import tokenServices from "./services/token.service";
import formServices from "./services/form.service";
import { createToast, clearToasts } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import "gitart-vue-dialog/dist/style.css";
import "vue-multiselect/dist/vue-multiselect.css"
import { GDialog } from "gitart-vue-dialog";
import { Multiselect } from "vue-multiselect";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faMinusCircle, faCheckCircle, faBars, faThLarge, faBuilding, faCog, faChartPie, faExclamationCircle, faBriefcase, faEraser, faBook, faArrowLeft, faArrowRight, faHome, faTh, faThList, faHotel, faFilter, faTimes, faCheckSquare, faPlus, faUser, faEdit, faEuroSign, faUsers, faCheck, faUndo, faEllipsisV, faSearch, faMoneyBill, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import setupInterceptors from './services/setupInterceptors';
import Validator from './common/utils/Validator'
import mixin from './mixin'

if (!process.env.VUE_APP_API_URL) {
    console.error("Unknown VUE_APP_API_URL environment variable");
}

const app = createApp(App)

app.component('default-layout', DefaultLayout);
app.component('empty-layout', EmptyLayout);
app.component('user-icon', UserIcon)

app.use(store);
setupInterceptors(store);
app.use(router);
app.component('GDialog', GDialog);
app.component('vue-multiselect', Multiselect)

library.add(faSpinner, faMinusCircle, faCheckCircle, faBars, faThLarge, faBuilding, faCog, faChartPie, faExclamationCircle, faBriefcase, faEraser, faBook, faArrowLeft, faArrowRight, faHome, faTh, faThList, faHotel, faFilter, faTimes, faSquare, faCheckSquare, faPlus, faUser, faEdit, faEuroSign, faUsers, faCheck, faUndo, faEllipsisV, faSearch, faMoneyBill, faChevronDown, faChevronRight);
app.component('font-awesome-icon', FontAwesomeIcon);

app.provide('api', api);
app.provide('tokenServices', tokenServices);
app.provide('formServices', formServices);
app.mixin(mixin)
app.config.globalProperties.$validator = Validator;
app.config.globalProperties.$api = api;
app.config.globalProperties.$clearToast = clearToasts;
app.config.globalProperties.$toast = createToast;
app.config.globalProperties.$toastSuccess = {type: 'success', transition: 'slide', toastBackgroundColor: "#1BAD6E"}
app.config.globalProperties.$toastError = {type: 'danger', transition: 'slide', toastBackgroundColor: "#D10F1D", timeout: 10000}
app.config.globalProperties.$multiselectBind = {
    placeholder: "Séléctionner une option",
    selectLabel: "",
    selectGroupLabel: "",
    selectedLabel: "Séléctionné",
    deselectLabel: "",
    deselectGroupLabel: ""
}


app.mount('#app')