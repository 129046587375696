<template>
<div className="flex flex-col">
    <template v-if="loaded">
        <LoyerList :bien="bien" />
        <ExpenseList />
        <TaxeFonciereList />
    </template>
    <SpinnerLoader v-else class="mt-5"></SpinnerLoader>
</div>
</template>

<script>
import LoyerList from './../../components/bien/details/financials/LoyerList';
import TaxeFonciereList from './../../components/bien/details/financials/TaxeFonciereList';
import ExpenseList from './../../components/bien/details/financials/ExpenseList';
import SpinnerLoader from './../../components/loader/SpinnerLoader';
import { inject } from 'vue'

export default {
    components: {
        LoyerList,
        TaxeFonciereList,
        ExpenseList,
        SpinnerLoader
    },

    data() {
        return {
            bien: null,
            loaded: false,
        }
    },

    created() {
        this.loadBien()
    },

    methods: {
        async loadBien() {
            const api = inject('api');

            this.loaded = false

            this.bien = await api.bien.getBien(this.$route.params.bienUuid);

            this.loaded = true
        },
    }
};
</script>