import axiosInstance from "./api";
import TokenService from "./token.service";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalToken();
      if (token && !["/api/token/refresh"].includes(config.url)) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url === "/api/token/refresh" && err.response.status === 401) {
        store.dispatch('auth/logout');
        TokenService.removeUser();

        return Promise.resolve();
      }

      if (originalConfig.url !== "/api/login_check" && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const rs = await axiosInstance.post("/api/token/refresh", {
                refresh_token: TokenService.getLocalRefreshToken(),
            });
console.log(rs)
            const { token } = rs.data;

            store.dispatch('auth/refreshToken', token);
            TokenService.updateLocalToken(token);

            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;