<template>
    <GDialog 
        v-model="open" 
        :max-width="width"
        transition="custom-from-bottom-transition"
    >
        <div class="p-6">
            <slot name="title"><h3 class="text-2xl text-bold">{{ title }}</h3></slot>
        </div>
        <hr>
        <div class="p-6">
            <slot name="content"></slot>
        </div>
        <hr>
        <div class="p-6">
            <div class="flex w-full justify-between items-center">
                <div class="flex flew-wrap">
                    <slot name="footer"><div></div></slot>
                </div>
                <div class="flex justify-end">
                    <button
                        class="mr-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border text-sm border-gray-500 hover:border-transparent rounded-md"
                        @click="dismissModal()"
                        v-if="btnDismiss"
                    >
                        {{ btnDismissText }}
                    </button>
                    <LoaderButton :title="btnSuccessText" :defaultClass="classButton" @ok="$emit('ok')" :disabled="disabledBtn" ref="btnValidate"></LoaderButton>
                </div>
            </div>
        </div>
    </GDialog>
</template>

<script>

import LoaderButton from './../form/LoaderButton';

export default {
    components: {
        LoaderButton
    },
    data() {
        return {
            open: false,
        }
    },
    emits: ['ok'],
    props: {
        width: {
            type: Number,
            default: 500
        },
        title: String,
        btnDismiss: {
            type: Boolean,
            default: true
        },
        btnDismissText: {
            type: String,
            default: "Annuler"
        },
        btnSuccessText: {
            type: String,
            default: "Valider"
        },
        disabledBtn: {
            type: Boolean,
            default: false,
        },
        footerInfo: {
            type: String,
            default: ''
        },
        classButton: {
            type: String,
            default: "btn-primary"
        }
    },
    methods: {
        openModal() {
            this.open = true;
        },
        dismissModal() {
            this.open = false;
        },
        activateLoader() {
            this.$refs.btnValidate.activateLoader();
        },
        deactivateLoader() {
            this.$refs.btnValidate.deactivateLoader();
        }
    },
};
</script>
<style lang="scss">

.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
</style>