<template>
<div class="flex flex-wrap">
    <AppInput 
        v-model='keywordState'
        title='Mots-clés' 
        id='mots-cles' 
        :placeholder="placeholder"
        class="mr-5 w-64"
        @onEnter="$emit('search')"
    />
    <SwitchForm v-if="!disableArchived" v-model='archivedState' @edit="$emit('search')" title='Archivé' id='archived' class="mr-3"/>
</div>
</template>

<script>

import AppInput from '../form/AppInput';
import SwitchForm from '../form/SwitchForm';
import { useVModel } from '../composables/useVModel'

export default {
    components: {
        AppInput,
        SwitchForm
    },

    emits: [
        'update:keyword',
        'update:archived',
        'search'
    ],

    props: {
        keyword: String,
        archived: Number,
        placeholder: {
            type: String,
            default: ""
        },
        disableArchived: {
            type: Boolean,
            default: false
        }
    },

    setup(props) {
        return {
            keywordState: useVModel(props, 'keyword'),
            archivedState: useVModel(props, 'archived'),
        }
    },
};
</script>