<template>
    <div  v-if="loaded" class="grid grid-cols-1 md:grid-cols-5 mt-3">
        <div class="md:col-span-3 pr-5 md:border-r border-gray-400">
            <div class="flex justify-end">
                <LoaderButton title="Enregistrer" @ok="handleUpdateSci" :disabled="checkFieldValid" ref="btnValidate"></LoaderButton>
            </div>
            <AppInput 
                v-model='sci.libelle' 
                class="mt-3" 
                title='Libelle' 
                id='libelle' 
                :validations="['required']"
                @hasError="handleFormError"
            />
            <div class="grid grid-cols-1 md:grid-cols-3">
                <AppInput 
                    v-model='sci.capital' 
                    class="mt-3 md:pr-2" 
                    title='Capital (en €)' 
                    id='capital'
                    :validations="['integer']"
                    @hasError="handleFormError"/>
                <AppInput v-model='sci.rcs_numeros' class="mt-3 md:pr-2" title='Numéros RCS' id='rcs_num'/>
                <AppInput v-model='sci.rcs_ville' class="mt-3" title='Ville du RCS' id='rcs_ville'/>
            </div>
            <AppInput v-model='sci.adresse' class="mt-3" title='Adresse' id='adresse'/>
            <div class="grid grid-cols-1 md:grid-cols-2">
                <AppInput v-model='sci.cp' class="mt-3 md:pr-2" title='Code postal' id='cp'/>
                <AppInput v-model='sci.ville' class="mt-3" title='Ville' id='ville'/>
            </div>
        </div>
        <div class="md:col-span-2">
            <div class="flex justify-center">
                <h2 class="text-gray-900 text-lg mt-3">Membre<template v-if="sci.sci_membres.length > 1">s</template></h2>
                <hr class="mt-3 h-px bg-gray-900 border-0 dark:bg-gray-700">
            </div>
            <div v-for="(item, index) in sci.sci_membres" :key="index" class="flex flex-wrap justify-start items-center rounded-md md:mx-5 mt-5 p-5 border border-indigo-400 bg-indigo-50">
                <user-icon :user="item.utilisateur"/>
                <div class="ml-3">
                    <p class="text-sm text-gray-900">
                        {{item.utilisateur.prenom}} {{item.utilisateur.nom}} 
                        <span v-if="item.admin" class="text-xs px-2 cursor-default bg-yellow-500 text-white rounded-full">Admin</span>
                    </p>
                    <p class="text-sm text-gray-500">{{item.utilisateur.email}}</p>
                </div>
            </div>
        </div>
    </div>
    <SpinnerLoader v-else class="mt-5"></SpinnerLoader>
</template>

<script>
import { inject } from 'vue'
import AppInput from './../../components/form/AppInput';
import SpinnerLoader from './../../components/loader/SpinnerLoader';
import LoaderButton from './../../components/form/LoaderButton';

export default {
    components: {
        LoaderButton,
        SpinnerLoader,
        AppInput
    },
    data() {
        return {
            sci: {},
            loaded: false,
            formInvalid: [],
        }
    },
    computed: {
        checkFieldValid() {
            return this.formInvalid.length !== 0;
        }
    },
    created() {
        this.loadSci()
    },
    methods: {
        async loadSci() {
            const api = inject('api');

            this.loaded = false
            this.sci = await api.sci.getSci(this.$route.params.sciUuid);
            this.loaded = true
        },

        showRole(item) {
            if(item.admin) {
                return 
            }
        },
        handleFormError(event) {
            if (event.value && !this.formInvalid.includes(event.name)) {
                this.formInvalid.push(event.name);
            }

            if (!event.value && this.formInvalid.includes(event.name)) {
                this.formInvalid = this.formInvalid.filter(f => f !== event.name)
            }
        },
        async handleUpdateSci() {
            if (this.checkFieldValid) {
                return;
            }

            this.$refs.btnValidate.activateLoader();

            await this.$api.sci.updateSci(this.sci.uuid, {
                libelle: this.sci.libelle,
                capital: parseInt(this.sci.capital),
                rcs_ville: this.sci.rcs_ville,
                rcs_numeros: this.sci.rcs_numeros,
                adresse: this.sci.adresse,
                cp: this.sci.cp,
                ville: this.sci.ville
            })
            .then(() => {
                this.$toast({title: 'Succès', description: "La SCI à bien été enregistré."}, this.$toastSuccess);
            })
            .catch((err) => {
                console.log(err);
                this.$toast({title: 'Erreur', description: "Une erreur est survenue, veuillez réessayer plus tard."}, this.$toastError);
            })
            
            this.$refs.btnValidate.deactivateLoader();
        }
    },
};
</script>