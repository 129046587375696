<template>
    <div v-if="locataires && locataires.length !== 0">
        <div v-for="(locataire, index) in locataires" :key="index">
            <hr>
            <router-link :to="`/locataires/${locataire.uuid}`">
                <div class="flex flex-wrap justify-start items-center rounded-md my-5">
                    <user-icon :user="locataire"/>
                    <div class="ml-3">
                        <p class="text-sm font-normal text-gray-900 hover:text-indigo-500 hover:font-bold">
                            {{locataire.prenom}} {{locataire.nom}} 
                        </p>
                        <p class="text-sm text-gray-500">{{locataire.email}}</p>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
    <div class="mt-5" v-else>
        <hr>
        <div class="flex flex-col items-center justify-center mt-5">
            <p class="text-sm text-indigo-400">Aucun locataire n'a été trouvé.</p>
            <p @click="$emit('resetFilter')" class="text-sm text-indigo-600 cursor-pointer hover:text-indigo-300 mt-2">
                <font-awesome-icon class="text-lg" :icon="['fa', 'eraser']" />
                Réinitialiser les filtres.
            </p>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        locataires: {
            type: Array,
            default() {
                return []
            }
        },
    }
};
</script>