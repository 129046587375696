import api from "./api";
import tokenService from "./token.service";
import { catchValidationErrors } from "./catchErrors";

class UserService {
    async getCurrentUser() {
        const { data } = await api.get("/api/utilisateur");

        return data;
    }

    async updateUser(uuid, data) {
        return await api.put(`/api/utilisateurs/${uuid}`, data).catch(catchValidationErrors);
    }

    async changeUserPassword(uuid, data) {
        return await api.patch(`/api/utilisateurs/${uuid}/password`, data).catch(catchValidationErrors);
    }

    async updateSettings(key, value) {
        tokenService.updateSettings(key, value);
        const user = tokenService.getUser();

        await api.patch("/api/utilisateur/settings", user.settings).catch(catchValidationErrors);
    }
}

export default new UserService();