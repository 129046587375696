<template>
    <div class="grid grid-cols-1 md:grid-cols-5 mt-3">
        <div class="md:col-span-12 pr-5">
            <DefaultForm
                v-model:keyword="form.keyword"
                v-model:archived="form.archived"
                @search="loadHabitants()"
                placeholder="Email, Nom, Prénom"
                class="mt-3"
            />
            <ListItem
                :habitants="habitants"
                :loaded="loaded"
                @changePage="changePage"
                @handleUpdateList="loadHabitants()"
                class="mt-2"
                :filters="form"
            />
        </div>
    </div>
</template>

<script>
import ListItem from './../../components/bien/details/habitants/ListItem';
import DefaultForm from './../../components/form-group/DefaultForm';

export default {
    components: {
        ListItem,
        DefaultForm
    },
    data() {
        return {
            habitants: {},
            loaded: false,
            form: {
                keyword: "",
                archived: 0
            }
        }
    },
    created() {
        this.loadHabitants()
    },
    methods: {
        async changePage(page) {
            await this.loadHabitants(page)
        },

        async loadHabitants(page = 1) {
            this.loaded = false

            this.habitants = await this.$api.habitant.getHabitants(page, this.$route.params.bienUuid, this.form);

            this.loaded = true
        },
    },
};
</script>